<div class="card mb-4">
    <div class="card-body">
      <cpa-h5 class="card-title box-title" [additionalClasses]='additionalClasses' >{{tag}}</cpa-h5>
      <cpa-h2 *ngIf="!!title" class="card-title">{{title}}</cpa-h2>
      <p *ngIf="!!description" class="card-text">{{description}}</p>
      <ng-content></ng-content>
  </div>
</div>


