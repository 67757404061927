import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { ObjectUtils } from 'primeng/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GridOptions } from './cpa-grid.options';
import { Table } from 'primeng/table';

@Component({
  selector: 'cpa-grid',
  templateUrl: './cpa-grid.component.html',
  styleUrls: ['./cpa-grid.component.scss'],
})
export class CpaGridComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() public options: GridOptions;
  @Input() public templates: TemplateRef<any>[];
  @Input() public captionTemplate: TemplateRef<any>;
  @Input() public paginatorLeftTemplate: TemplateRef<any>;
  @Input() public paginatorRightTemplate: TemplateRef<any>;

  private filterText: string;
  get filter(): string {
    return this.filterText;
  }
  @Input()
  set filter(val: string) {
    this.filterText = val;
    this.onFilterChanged();
  }
  @Input() globalFilterFields = [];
  @Output() public lazyLoaded: EventEmitter<LazyLoadEvent> = new EventEmitter<LazyLoadEvent>();
  @Output() rowClick: EventEmitter<any> = new EventEmitter();
  @Output() rowSelection: EventEmitter<any[]> = new EventEmitter();
  @ViewChild(Table) private tbl: Table;

  public hasFilters: boolean;
  public first = 0;
  private rowsPerPageOptions = [10, 20, 50, 100];
  private unsubscriber = new Subject<any>();
  @ViewChild(Table, { static: true }) datatable: Table;

  ngOnInit() {
    this.hasFilters = this.options.columns.some((c) => c.filter);
    // Enable initial lazy load by default
    if (this.options.lazyLoadOnInit === null || this.options.lazyLoadOnInit === undefined) {
      this.options.lazyLoadOnInit = true;
    }
  }

  private onFilterChanged() {
    if (this.tbl) {
      this.tbl.filterGlobal(this.filter, 'contains');
    }
  }

  ngAfterViewInit() {
    this.datatable.tableService.selectionSource$.pipe(takeUntil(this.unsubscriber)).subscribe((value: unknown) => {
      this.rowSelection.emit(this.datatable.selection);
    });
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  public getSelection() {
    return this.datatable.selection;
  }

  public selectedRowByDataKey(dataKeyValue: any) {
    if (!this.options.dataKey) {
      console.error('No dataKey defined in GridOptions!');
      return;
    }
    if (!this.datatable.isSelected({ [this.options.dataKey]: dataKeyValue })) {
      const items = this.options.items.filter((v: any) => ObjectUtils.resolveFieldData(v, this.options.dataKey) === dataKeyValue);
      if (items.length > 0) {
        this.datatable.toggleRowWithCheckbox({}, items[0]);
      }
    }
  }

  public unselectedRowByDataKey(dataKeyValue: any) {
    if (!this.options.dataKey) {
      console.error('No dataKey defined in GridOptions!');
      return;
    }
    if (this.datatable.isSelected({ [this.options.dataKey]: dataKeyValue })) {
      const items = this.datatable.selection.filter((v: any) => ObjectUtils.resolveFieldData(v, this.options.dataKey) === dataKeyValue);
      this.datatable.toggleRowWithCheckbox({}, items[0]);
    }
  }

  public getTotalRecords() {
    return this.options.enableLazyLoading ? this.options.pagination && this.options.pagination.totalRecords : this.options.items.length;
  }

  public getRowsPerPageOptions() {
    return (this.options.pagination && this.options.pagination.rowsPerPageOptions) || this.rowsPerPageOptions;
  }

  public isRowClickable(): boolean {
    return this.rowClick.observers.length > 0;
  }

  public lazyLoad(event: LazyLoadEvent) {
    this.lazyLoaded.emit(event);
  }

  public onPaginate(event: { first: number; rows: number }) {
    this.first = event.first;
  }
}
