<div ngbDropdown display="dynamic" placement="bottom-right" class="position-inherit">
  <div id="profile" class="profile-image small pointer" title="Profile" ngbDropdownToggle>
    <fa-icon [icon]="faUser"></fa-icon>
  </div>
  <div class="w-312" aria-labelledby="profile" ngbDropdownMenu>
    <div class="dropdown-header border-bottom">
      <div class="profile-image-area d-inline-block" id="profile">
        <div class="profile-image large" [title]="user || ''">
          <fa-icon [icon]="faUser"></fa-icon>
        </div>
      </div>
      <div class="profile-name-area align-middle d-inline-block">
        <div class="font-weight-bold text-capitalize profile-name" [title]="user || 'Loading...'">
          {{user || 'Loading...'}}</div>
        <small class="text-muted" [title]="organisation || 'Organization'">{{organisation || 'Organization'}}</small>
      </div>
    </div>
    <a *ngIf="isAdmin" title="IP Platform Admin" class="dropdown-item" [href]="coreHome + '/#/admin'">IP Platform
      Admin</a>
    <a title="Settings" class="dropdown-item" [href]="coreHome + '/#/settings/profile'">Settings</a>
    <a title="Service Catalogue" class="dropdown-item" [href]="coreHome + '/#/marketplace'">Service Catalogue</a>
    <button title="Log out" type="button" class="dropdown-item" (click)="onLogout()">Log out</button>
  </div>
</div>
