import {
  Component,
  Input,
  AfterViewInit,
  OnDestroy,
  OnChanges,
  ElementRef,
  SimpleChanges,
  ChangeDetectionStrategy,
  NgZone,
  HostBinding,
  OnInit,
} from '@angular/core';
import Popper from 'popper.js';

@Component({
  selector: 'cpa-popper,cpa-overlay-panel,cpa-tooltip',
  templateUrl: './cpa-popper.component.html',
  styleUrls: ['./cpa-popper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CpaPopperComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() placement: Popper.Placement = 'bottom';
  @Input() positionFixed = false;
  @Input() eventsEnabled = true;
  @Input() modifiers: Popper.Modifiers = { keepTogether: { enabled: true }};

  tooltip: boolean;

  @HostBinding('class.show')
  get showing() { return this._target; }

  private _target: any;

  set target(target: any) {
    this._target = target;
  }

  get target() {
    return this._target;
  }

  private popper: Popper;

  constructor(private el: ElementRef, private zone: NgZone) { }

  ngAfterViewInit() {
    this.create();
  }

  ngOnInit() {
    this.tooltip = this.isCpaTooltip();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.placement && !changes.placement.firstChange ||
      changes.positionFixed && !changes.positionFixed.firstChange ||
      changes.eventsEnabled && !changes.eventsEnabled.firstChange
    ) {
      this.refresh();
    }
  }

  refresh() {
    this.destroy();
    this.create();
  }

  toggle(event: any, target?: any) {
    if (this.target) {
      this.target = null;
    } else {
      this.target = target || event.currentTarget || event.target;
    }
    this.refresh();
  }

  open(event: any, target?: any) {
    this.target = target || event.currentTarget || event.target;
    this.refresh();
  }

  close() {
    this.target = null;
  }

  ngOnDestroy() {
    this.destroy();
  }

  create() {
    if (this.target) {
      this.zone.runOutsideAngular(() => {
        const { placement, positionFixed, eventsEnabled, modifiers } = this;

        this.popper = new Popper(
          this.getTargetNode(),
          this.el.nativeElement.querySelector('.popper'),
          {
            placement,
            positionFixed,
            eventsEnabled,
            modifiers
          }
        );
      });
    }
  }

  destroy() {
    if (this.popper) {
      this.zone.runOutsideAngular(() => {
        this.popper.destroy();
      });

      this.popper = null;
    }
  }

  private isCpaTooltip(): boolean {
    const tagName = this.el.nativeElement.tagName.toLowerCase();
    return tagName === 'cpa-tooltip';
  }

  private getTargetNode(): Element {
    if (this.target) {
      if (typeof this.target === 'string') {
        return document.querySelector(this.target);
      } else {
        return this.target;
      }
    } else {
      return this.el.nativeElement;
    }
  }
}
