<div ngbDropdown display="dynamic" placement="bottom-right" class="position-inherit">
    <fa-icon id="apps" class="pointer" [icon]="faBorderAll" title="Applications" [fixedWidth]="true" ngbDropdownToggle></fa-icon>
    <div class="w-312" aria-labelledby="apps" ngbDropdownMenu>
        <div class="dropdown-header border-bottom font-weight-bold">My apps</div>
        <a class="dropdown-item clickable" *ngFor="let app of installedApps" [href]="app.configuration.startURL || app.configuration.defaultStartURL" [title]="app.manifest.name">
          {{app.manifest.name}}
        </a>
        <div class="dropdown-item text-muted click-event" *ngIf="!installedApps.length" title="Loading...">Loading...</div>
        <a class="dropdown-item border-above clickable" [href]="coreHome" title="View all apps">View all apps <fa-icon [icon]="faChevronRight"></fa-icon></a>
    </div>
</div>
