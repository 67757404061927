import { Input,  Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, AbstractControl, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'cpa-text-area',
  templateUrl: './cpa-text-area.component.html',
  styleUrls: ['./cpa-text-area.component.scss'],
  providers: [UntypedFormBuilder],
})
export class CpaTextAreaComponent implements OnInit {
  @Input() public textAreaRows: number;
  @Input() public textAreaCols: number;
  @Input() public textAreaLabelId = '';
  @Input() public textAreaId = '';
  @Input() public label = '';
  @Input() public helpText = '';
  @Input() public textAreaPlaceHolder = '';
  @Input() public form: UntypedFormGroup | undefined;
  @Input() public formFieldName = 'defaultFormFieldName';
  @Input() public readonly = false;
  @Input() public disabled = false;
  @Output() public textAreaValue = new EventEmitter<string>();


  get calculatedDisabledState() {
    return (!!this.disabled || (!!this.readonly && !this.form.controls[this.formFieldName].value));
  }

  constructor() { }

  ngOnInit() {
    if (!this.form) {
      this.form = new UntypedFormGroup({
        defaultFormFieldName: new UntypedFormControl(),
      });
    }

    // Listen for changes to the control input.
    this.form.controls[this.formFieldName].valueChanges.subscribe(() => {
      const value = this.form.get(this.formFieldName).value;
      this.textAreaValue.emit(value);
    });
  }

  isRequired() {
    const fc = this.form.get(this.formFieldName);

    if (fc && fc.validator) {
      const validators = fc.validator({} as AbstractControl);
      return (validators || {}).required;
    }
  }

  hasError() {
    const fc = this.form.get(this.formFieldName);
    return fc.invalid && (fc.touched || fc.dirty);
  }
}
