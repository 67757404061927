import { Component, Input, Output, EventEmitter, OnInit, HostListener} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'cpa-modal',
  templateUrl: './cpa-modal.component.html',
  styleUrls: ['./cpa-modal.component.scss'],
})
export class CpaModalComponent {

  @Input() public header: string;
  @Input() public modalSize: 'modal-sm' | 'default' | 'modal-lg' | 'modal-xl' = 'default';
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();

  canScrollUp = false;
  canScrollDown = false;

  canScrollUp$: BehaviorSubject<boolean>;
  canScrollDown$: BehaviorSubject<boolean>;

  @HostListener('document:keydown', ['$event'])
  onKeyDownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeIconClick();
    }
  }

  @HostListener('document:click', ['$event'])
  onOutsideCliek(event: MouseEvent) {
    const target = event.target as HTMLDivElement;
    if (!!target && (target.attributes.getNamedItem('role')?.value === 'dialog')) {
      this.closeIconClick();
    }
  }

  constructor() {
  }

  public closeIconClick(): void {
    this.closeEvent.emit();
  }

  canScroll(upOrDown: 'up' | 'down', value: boolean) {
    setTimeout(() => {
      if (upOrDown === 'up') {
        this.canScrollUp = value;
      }
      if (upOrDown === 'down') {
        this.canScrollDown = value;
      }
    }, 0);
  }
}
