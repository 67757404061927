import { Component, OnInit } from '@angular/core';
import { faCloudUpload } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'cpa-cloud-icon',
  templateUrl: './cpa-cloud-icon.component.html',
  styleUrls: ['./cpa-cloud-icon.component.scss'],
})
export class CpaCloudIconComponent implements OnInit {
  public faCloudUpload = faCloudUpload;

  constructor() { }

  ngOnInit() {
  }

}
