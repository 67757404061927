
<div class="input-group-text form-group">
  <cpa-helptext
      *ngIf="!!helpText"
      [ngClass]="{'isDisabled': calculatedDisabledState}">{{helpText}}</cpa-helptext>

  <div class="input-wrapper">
    <fa-icon *ngIf="isIconTypeString" [icon]="[iconType, icon]" aria-hidden="true"></fa-icon>
    <fa-icon *ngIf="isIconTypeIconDefinition" [icon]="icon" aria-hidden="true"></fa-icon>
    <input
      class="form-control"
      [type]="type"
      [placeholder]="placeholder"
      [readonly]="readonly || disabled"
      [disabled]="calculatedDisabledState"
      [autocomplete]="autocomplete"
      [ngModel]="value"
      (ngModelChange)="writeValue($event)"
      (blur)="onTouched()"
      (keyup.enter)="onEnterKeyPressed()" />
    <fa-icon *ngIf="showClear" class="input-clear-icon" [icon]="clearIcon" aria-hidden="true" (click)="clearValue()"></fa-icon>
  </div>

  <label *ngIf="!!label">
      <cpa-h5>{{label}}</cpa-h5>
  </label>
</div>
