import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CpaCloseIconComponent } from './cpa-close-icon/cpa-close-icon.component';
import { CpaH1Component } from './cpa-h1/cpa-h1.component';
import { CpaH2Component } from './cpa-h2/cpa-h2.component';
import { CpaH5Component } from './cpa-h5/cpa-h5.component';
import { CpaVideoModalComponent } from './cpa-video-modal/cpa-video-modal.component';
import { CpaPrimaryButtonComponent } from './buttons/cpa-primary-button/cpa-primary-button.component';
import { CpaSecondaryButtonComponent } from './buttons/cpa-secondary-button/cpa-secondary-button.component';
import { CpaSecondaryOutlineButtonComponent } from './buttons/cpa-secondary-outline-button/cpa-secondary-outline-button.component';
import { CpaPrimaryOutlineButtonComponent } from './buttons/cpa-primary-outline-button/cpa-primary-outline-button.component';
import { SafePipe } from './safe.pipe';
import { FilePickerModule } from './cpa-file-picker/file-picker.module';
import { CpaCardComponent } from './cpa-card/cpa-card.component';
import { CpaDropdownListComponent } from './cpa-dropdown-list/cpa-dropdown-list.component';
import { CpaModalComponent } from './cpa-modal/cpa-modal.component';
import { CpaModalSubHeaderComponent } from './cpa-modal-sub-header/cpa-modal-sub-header.component';
import { CpaTextAreaComponent } from './cpa-text-area/cpa-text-area.component';
import { CpaTextInputComponent } from './cpa-text-input/cpa-text-input.component';
import { CpaCardSideComponent } from './cpa-card-side/cpa-card-side.component';
import { CpaBreadcrumbComponent } from './cpa-breadcrumb/cpa-breadcrumb.component';
import { CpaRouterBreadcrumbsComponent } from './cpa-router-breadcrumbs/cpa-router-breadcrumbs.component';
import { CpaContactPersonComponent } from './cpa-contact-person/cpa-contact-person.component';
import { CpaTitleComponent } from './cpa-title/cpa-title.component';
import { CpaCheckboxComponent } from './cpa-checkbox/cpa-checkbox.component';
import { CpaErrortextComponent } from './cpa-errortext/cpa-errortext.component';
import { CpaHelptextComponent } from './cpa-helptext/cpa-helptext.component';
import { SideMenuComponent } from './cpa-sidemenu/sidemenu.component';
import { CommonModule } from '@angular/common';
import { SideMenuCicDirective } from './cpa-sidemenu/sidemenu-cic.directive';
import { CpaDatepickerComponent } from './cpa-datepicker/cpa-datepicker.component';
import { CpaDatepickerRangeComponent } from './cpa-datepicker-range/cpa-datepicker-range.component';
import { CpaDatepickerRangeCompactComponent } from './cpa-datepicker-range-compact/cpa-datepicker-range-compact.component';

import { CpaMultiselectDropdownListComponent } from './cpa-multiselect-dropdown-list/cpa-multiselect-dropdown-list.component';
import { CpaGridComponent } from './cpa-grid/cpa-grid.component';
import { CpaTypeaheadComponent } from './cpa-typeahead/cpa-typeahead.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MultiSelectModule } from 'primeng/multiselect';
import { CpaSideMenuModule } from './cpa-side-menu/side-menu.module';
import { CpaMainComponent } from './cpa-main/main.component';
import { CpaProgressMenuComponent } from './cpa-progress-menu/cpa-progress-menu.component';
import { CpaProgressMenuItemComponent } from './cpa-progress-menu/cpa-progress-menu-item/cpa-progress-menu-item.component';
import { CpaToggleComponent } from './cpa-toggle/cpa-toggle.component';
import { CpaSelectComponent } from './cpa-select/cpa-select.component';
import { CpaSelectItemComponent } from './cpa-select/cpa-select-item/cpa-select-item.component';
import { TooltipModule } from 'primeng/tooltip';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { CpaReadOnlyDataPlaceholderComponent } from './cpa-read-only-data-placeholder/cpa-read-only-data-placeholder.component';
import { CpaH4DefaultComponent } from './headings/cpa-h4-default/cpa-h4-default.component';
import { CpaH1PrimaryComponent } from './headings/cpa-h1-primary/cpa-h1-primary.component';
import { CpaSmallDefaultComponent } from './headings/cpa-small-default/cpa-small-default.component';
import { CpaPageComponent } from './cpa-page/cpa-page.component';
import { DirectivesModule } from './directives/directives.module';
import { CpaExpansionPanelComponent } from './cpa-expansion-panel/cpa-expansion-panel.component';
import { CpaAccordionComponent } from './cpa-accordion/cpa-accordion.component';
import { CpaInputChipsComponent } from './cpa-input-chips/cpa-input-chips.component';
import { CpaChipComponent } from './cpa-chip/cpa-chip.component';
import { CpaInputComponent } from './internal/cpa-input/cpa-input.component';
import { CpaChoiceChipsComponent } from './cpa-choice-chips/cpa-choice-chips.component';
import { CpaFilterChipsComponent } from './cpa-filter-chips/cpa-filter-chips.component';
import { CpaButtonComponent } from './buttons/cpa-button/cpa-button.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { EditorModule } from 'primeng/editor';
import { CpaRadioButtonComponent } from './cpa-radio-button/cpa-radio-button.component';
import { CpaOverlayModule } from './directives/cpa-overlay/cpa-overlay.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CpaRichTextEditorComponent } from './cpa-rich-text-editor/cpa-rich-text-editor.component';
import { CpaLoadingInlineModule } from './cpa-loading-inline/cpa-loading-inline.module';
import { CpaLoadingFullscreenModule } from './cpa-loading-fullscreen/cpa-loading-fullscreen.module';
import { CpaModalHeaderLeftComponent } from './cpa-modal/cpa-modal-header-left/cpa-modal-header-left.component';
import { CpaModalFooterComponent } from './cpa-modal/cpa-model-footer/cpa-model-footer.component';
import { CpaTreeGridComponent } from './cpa-tree-grid/cpa-tree-grid.component';
import { TreeTableModule } from 'primeng/treetable';
import {
CpaDatePickerWithReactiveFormsSupportComponent
} from './cpa-date-picker-with-reactive-forms-support/cpa-date-picker-with-reactive-forms-support.component';
import { CpaFileModule } from './cpa-file/cpa-file.module';
import { CpaPopperModule } from './cpa-popper/cpa-popper.module';
import { CpaFileListModule } from './cpa-file-list/cpa-file-list.module';
import { CpaFileImageModule } from './cpa-file-img/cpa-file-img.module';
import { CpaFaIconModule } from './cpa-fa-icon/cpa-fa-icon.module';
import { CpaFileUploaderModule } from './cpa-file-uploader/cpa-file-uploader.module';
import { CpaFileDropZoneModule } from './cpa-file-drop-zone/cpa-file-drop-zone.module';
import { SharedModule } from 'primeng/api';
import { PaginatorModule } from 'primeng/paginator';

@NgModule({
  declarations: [
    CpaCardComponent,
    CpaCloseIconComponent,
    CpaGridComponent,
    CpaH1Component,
    CpaH2Component,
    CpaH5Component,
    CpaVideoModalComponent,
    SafePipe,
    CpaPrimaryButtonComponent,
    CpaSecondaryButtonComponent,
    CpaSecondaryOutlineButtonComponent,
    CpaPrimaryOutlineButtonComponent,
    CpaCardSideComponent,
    CpaBreadcrumbComponent,
    CpaRouterBreadcrumbsComponent,
    CpaContactPersonComponent,
    CpaTitleComponent,
    CpaDropdownListComponent,
    CpaModalComponent,
    CpaModalSubHeaderComponent,
    CpaTextAreaComponent,
    CpaTextInputComponent,
    CpaCardSideComponent,
    CpaCheckboxComponent,
    CpaErrortextComponent,
    CpaHelptextComponent,
    SideMenuComponent,
    SideMenuCicDirective,
    CpaDatepickerComponent,
    CpaDatepickerRangeComponent,
    CpaMultiselectDropdownListComponent,
    CpaTypeaheadComponent,
    CpaMainComponent,
    CpaProgressMenuComponent,
    CpaProgressMenuItemComponent,
    CpaToggleComponent,
    CpaSelectComponent,
    CpaSelectItemComponent,
    CpaReadOnlyDataPlaceholderComponent,
    CpaH1PrimaryComponent,
    CpaH4DefaultComponent,
    CpaSmallDefaultComponent,
    CpaPageComponent,
    CpaAccordionComponent,
    CpaExpansionPanelComponent,
    CpaChipComponent,
    CpaInputChipsComponent,
    CpaChoiceChipsComponent,
    CpaFilterChipsComponent,
    CpaInputComponent,
    CpaDatepickerRangeCompactComponent,
    CpaButtonComponent,
    CpaRadioButtonComponent,
    CpaRichTextEditorComponent,
    CpaModalHeaderLeftComponent,
    CpaModalFooterComponent,
    CpaDatePickerWithReactiveFormsSupportComponent,
    CpaTreeGridComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    InputTextModule,
    FilePickerModule,
    ReactiveFormsModule,
    NgbModule,
    TooltipModule,
    SharedModule,
    ScrollingModule,
    DirectivesModule,
    RadioButtonModule,
    CpaOverlayModule,
    FontAwesomeModule,
    EditorModule,
    PaginatorModule,
    CpaFaIconModule,
    CpaFileUploaderModule,
    CpaFileDropZoneModule,
    TreeTableModule
  ],
  exports: [
    CpaCardComponent,
    CpaCardSideComponent,
    CpaCloseIconComponent,
    CpaGridComponent,
    CpaH1Component,
    CpaH2Component,
    CpaH5Component,
    CpaVideoModalComponent,
    SafePipe,
    CpaPrimaryButtonComponent,
    CpaSecondaryButtonComponent,
    CpaSecondaryOutlineButtonComponent,
    CpaPrimaryOutlineButtonComponent,
    CpaBreadcrumbComponent,
    CpaRouterBreadcrumbsComponent,
    CpaContactPersonComponent,
    CpaTitleComponent,
    CpaDropdownListComponent,
    CpaModalComponent,
    CpaModalSubHeaderComponent,
    CpaTextAreaComponent,
    CpaTextInputComponent,
    CpaPrimaryOutlineButtonComponent,
    FilePickerModule,
    CpaCheckboxComponent,
    CpaErrortextComponent,
    CpaHelptextComponent,
    SideMenuComponent,
    CpaDatepickerComponent,
    CpaDatepickerRangeComponent,
    CpaMultiselectDropdownListComponent,
    CpaTypeaheadComponent,
    CpaSideMenuModule,
    CpaMainComponent,
    CpaProgressMenuComponent,
    CpaProgressMenuItemComponent,
    CpaToggleComponent,
    CpaSelectComponent,
    CpaSelectItemComponent,
    SharedModule,
    ScrollingModule,
    CpaReadOnlyDataPlaceholderComponent,
    CpaH1PrimaryComponent,
    CpaH4DefaultComponent,
    CpaSmallDefaultComponent,
    CpaPageComponent,
    DirectivesModule,
    CpaAccordionComponent,
    CpaExpansionPanelComponent,
    CpaChipComponent,
    CpaInputChipsComponent,
    CpaChoiceChipsComponent,
    CpaFilterChipsComponent,
    CpaInputComponent,
    CpaDatepickerRangeCompactComponent,
    CpaButtonComponent,
    CpaRadioButtonComponent,
    CpaOverlayModule,
    FontAwesomeModule,
    CpaRichTextEditorComponent,
    CpaLoadingInlineModule,
    CpaLoadingFullscreenModule,
    CpaModalHeaderLeftComponent,
    CpaModalFooterComponent,
    CpaDatePickerWithReactiveFormsSupportComponent,
    CpaPopperModule,
    CpaFileModule,
    CpaFileImageModule,
    CpaFileListModule,
    CpaFaIconModule,
    CpaFileUploaderModule,
    CpaFileDropZoneModule,
    CpaTreeGridComponent
  ]
})
export class CpaUiModule { }
