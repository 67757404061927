<cdx-header>
  <h1 slot="cdx-header-product-name">{{appName}}</h1>
      <div *ngIf="!offline" style="display: flex; ">
      <cpa-notifications (settingloader)="loadSettings()" [apps]="apps" [notifications]="notifications"
                         [coreHome]="coreHome" class="nav-link">
      </cpa-notifications>

      <cpa-apps [apps]="apps" [coreHome]="coreHome" class="nav-link"></cpa-apps>

      <cpa-profile (logout)="onLogout()" [coreHome]="coreHome" [isAdmin]="isAdmin" [user]="user"
                   [organisation]="organisation" class="nav-link"></cpa-profile>
  </div>

</cdx-header>
<footer class="footer">
  <cdx-footer cookieManagementEnabled></cdx-footer>
</footer>