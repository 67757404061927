<label *ngIf="label" [class.disabled]="disabled" [class.required]="required">
  <cpa-h5>{{ label }}</cpa-h5>
</label>

<div class="radio-button-container">
  <p-radioButton
    *ngFor="let option of options | keyvalue: sortFn"
    class="form-check"
    [label]="option.key"
    [value]="option.value"
    [disabled]="disabled"
    [ngModel]="value"
    (onClick)="onModelChange(option.value)"
    (onBlur)="onTouched()">
  </p-radioButton>
</div>

<cpa-helptext *ngIf="helpText" [class.disabled]="disabled">
  {{ helpText }}
</cpa-helptext>
