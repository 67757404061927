import { NgModule } from '@angular/core';
import { CpaSideMenuHeaderComponent } from './cpa-side-menu-header/side-menu-header.component';
import { CpaSideMenuItemComponent } from './cpa-side-menu-item/side-menu-item.component';
import { CpaSideMenuComponent } from './side-menu.component';
import { CpaSideMenuCollapseComponent } from './cpa-side-menu-collapse/side-menu-collapse.component';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    CpaSideMenuHeaderComponent,
    CpaSideMenuItemComponent,
    CpaSideMenuCollapseComponent,
    CpaSideMenuComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
  ],
  exports: [
    CpaSideMenuHeaderComponent,
    CpaSideMenuItemComponent,
    CpaSideMenuCollapseComponent,
    CpaSideMenuComponent,
  ]
})
export class CpaSideMenuModule { }
