import {Directive, HostListener, Output, EventEmitter} from '@angular/core';

@Directive({
  selector: '[cpaDragAndDrop]'
})
export class CpaDragAndDropDirective {
  @Output() public dragFilesInside = new EventEmitter<void>();
  @Output() public dragFilesOutside = new EventEmitter<void>();
  @Output() public dropFiles = new EventEmitter<File[]>();

  constructor() { }

  @HostListener('dragover', ['$event']) public onDragOver($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.dragFilesInside.emit();
  }

  @HostListener('dragleave', ['$event']) public onDragLeave($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.dragFilesOutside.emit();
  }

  @HostListener('drop', ['$event']) public onDrop($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.dropFiles.emit($event);
  }
}
