
<div class="title">
    <span>{{title}}</span>
</div>
<div class="right-side-controls">
    <cpa-button
        type="danger"
        [small]="true"
        iconType="fal"
        [icon]="faTimes"
        (click)="clickOnCloseButton()">
    </cpa-button>
</div>