import { Component, OnInit, Input, HostBinding, Self, Optional, ChangeDetectorRef  } from '@angular/core';
import { ControlValueAccessor, NgControl, AbstractControl } from '@angular/forms';

interface Option {
  name: string;
  code: string;
}

@Component({
  selector: 'cpa-multiselect-dropdown-list',
  templateUrl: './cpa-multiselect-dropdown-list.component.html',
  styleUrls: ['./cpa-multiselect-dropdown-list.component.scss'],
})
export class CpaMultiselectDropdownListComponent implements OnInit, ControlValueAccessor {

  constructor(@Self() @Optional() private ngControl: NgControl, private changeDetector: ChangeDetectorRef) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  @HostBinding('class.required')
  get required(): boolean {
    if (this.ngControl && this.ngControl.control && this.ngControl.control.validator) {
      const validators = this.ngControl.control.validator({} as AbstractControl);
      return (validators || {}).required;
    }
  }

  @Input() options = [];
  @Input() placeholder = 'Select Options';
  @Input() helpText = '';
  @Input() selectedOptionValues = [] as Option[];
  @HostBinding('class.readonly')
  @Input() readonly: boolean;
  @HostBinding('class.disabled')
  @Input() disabled: boolean;

  optionValues = [];
  filterPlaceholder = 'Type to filter';
  onTouched: () => void = () => {};

  ngOnInit() {}

  onChange: (_: any) => void = (_: any) => {};

  writeValue(value: any): void {
    this.selectedOptionValues = value;
    this.changeDetector.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
