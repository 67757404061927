import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

import { TopbarComponent } from './core/topbar/topbar.component';
import { AppsComponent } from './core/topbar/apps/apps.component';
import { NotificationsComponent } from './core/topbar/notifications/notifications.component';
import { ProfileComponent } from './core/topbar/profile/profile.component';

import { BulletinsComponent } from './core/bulletins/bulletins.component';
import { BulletinsService } from './core/bulletins/bulletins.service';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBorderAll, faUser, faCircle, faCheck, faBan, faBriefcase, faBook, faCogs } from '@fortawesome/pro-solid-svg-icons';
import { faComment, faInfoCircle, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';

import { PLATFORM_API, PLATFORM_HOME, PLATFORM_VERSION, APP_NAME, APP_LOGO, API_AUTH_TOKEN } from './injections';
import { PlatformApiService } from './core/api/platform-api.service';
import { HtmlStripperUtil } from './core/utils/html-strip.util';
export { PLATFORM_API, PLATFORM_HOME, PLATFORM_VERSION, APP_NAME, APP_LOGO, API_AUTH_TOKEN };

@NgModule({
    declarations: [
        TopbarComponent,
        AppsComponent,
        NotificationsComponent,
        ProfileComponent,
        BulletinsComponent
    ],
    providers: [
        PlatformApiService,
        BulletinsService,
        HtmlStripperUtil
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        NgbDropdownModule,
        NgbToastModule
    ],
    exports: [
        TopbarComponent,
        BulletinsComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CpaCoreModule {
    constructor() {
        // tslint:disable-next-line:max-line-length
        library.add(faBorderAll, faComment, faUser, faCircle, faBan, faCheck, faInfoCircle, faExclamationTriangle, faBriefcase, faBook, faCogs);
    }
}
