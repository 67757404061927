import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpaReadableBytesPipe } from './readable-bytes.pipe';
import { FontAwesomeFileIconPipe } from './fa-file-icon.pipe';
import { ObjectMergePipe } from './object-merge.pipe';
import { ArrayJoinPipe } from './array-join.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    CpaReadableBytesPipe,
    FontAwesomeFileIconPipe,
    ObjectMergePipe,
    ArrayJoinPipe,
  ],
  exports: [
    CpaReadableBytesPipe,
    FontAwesomeFileIconPipe,
    ObjectMergePipe,
    ArrayJoinPipe,
  ]
})
export class PipesModule { }
