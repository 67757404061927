import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { CpaFileComponent } from './cpa-file.component';
import { PipesModule } from '../pipes/pipes.module';
import { CpaOverlayModule } from '../directives/cpa-overlay/cpa-overlay.module';
import { CpaPopperModule } from '../cpa-popper/cpa-popper.module';
import { DirectivesModule } from '../directives/directives.module';
import { CpaFileImageModule } from '../cpa-file-img/cpa-file-img.module';
import { CpaFileProgressBarComponent } from './cpa-file-progress-bar/cpa-file-progress-bar.component';
import { CpaFaIconModule } from '../cpa-fa-icon/cpa-fa-icon.module';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    PipesModule,
    CpaOverlayModule,
    CpaPopperModule,
    DirectivesModule,
    CpaFileImageModule,
    CpaFaIconModule
  ],
  declarations: [
    CpaFileComponent,
    CpaFileProgressBarComponent,
  ],
  exports: [
    CpaFileComponent,
  ]
})
export class CpaFileModule {}
