import { Directive, HostListener, ElementRef, Output, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[cpaScroll]'
})
export class CpaHasScrollDirective implements OnInit {

  @Output()
  canScrollUp: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @Output()
  canScrollDown: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.onScroll();
  }

  @HostListener('scroll', [])
  onScroll() {
      const divWrapper = this.elementRef.nativeElement as HTMLDivElement;
      const canScrollUp = divWrapper.scrollTop > 0;
      const canScrollDown = (divWrapper.scrollHeight - this.elementRef.nativeElement.clientHeight) - divWrapper.scrollTop > 0;

      if (this.canScrollUp.value !== canScrollUp) {
        this.canScrollUp.next(canScrollUp);
      }

      if (this.canScrollDown.value !== canScrollDown) {
        this.canScrollDown.next(canScrollDown);
      }
  }
}
