<div class="d-flex">
<div [ngClass]="{'itemBorder': true,
                    'itemBorderBackgroundColor': selected,
                    'itemBorderBackgroundColorHovered': hovered}"
></div>
<li (mouseleave)="onMouseLeave($event)" (mouseenter)="onMouseEnter($event)" (click)="onOptionClick($event)" role="option"
            [attr.aria-label]="option.label" [attr.aria-selected]="selected"
            class="e2e-select-item-{{option.value}}"
            [ngStyle]="{'height': itemSize + 'px'}"
            [ngClass]="{'ui-dropdown-item ui-corner-all':true,
                                                'ui-state-highlight': selected,
                                                'ui-state-highlight-hovered': hovered,
                                                'ui-state-highlight-hovered-selected': hoveredSelected,
                                                'ui-state-disabled':(option.disabled),
                                                'ui-dropdown-item-empty': !option.label||option.label.length === 0}">
            <div>
                <fa-icon *ngIf="multiSelect && !selected && !option.disabled" class="input-multiselect-icon" [icon]="faSquare" ></fa-icon>
                <fa-icon *ngIf="multiSelect && !selected && option.disabled" class="input-multiselect-icon" [icon]="faSquareFull" ></fa-icon>
                <fa-icon *ngIf="multiSelect && selected" class="input-multiselect-icon" [icon]="faCheckSquare" ></fa-icon>
            </div>
            <span *ngIf="!template">{{option.label||'empty'}}</span>
            <ng-container *ngTemplateOutlet="template; context: {$implicit: option}"></ng-container>
            <fa-icon *ngIf="optional" class="input-icon" [icon]="[iconType, icon]" (click)="onRemoveItem($event)"></fa-icon>
        </li>
</div>