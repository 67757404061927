import { Component, OnInit, Input, HostBinding, Output, Host, EventEmitter } from '@angular/core';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'cpa-chip',
  templateUrl: './cpa-chip.component.html',
  styleUrls: ['./cpa-chip.component.scss'],
})
export class CpaChipComponent implements OnInit {
  public faTimes = faTimes;

  @Input() icon: string | IconDefinition | undefined;
  @Input() thumbnailSrc: string;
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() selected: boolean;

  @HostBinding('class.selectable')
  get isSelectable() { return !this.disabled; }

  @HostBinding('class.selected')
  get isSelected() { return !this.disabled && this.selected; }

  constructor() { }

  ngOnInit() {
  }
}
