import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
  if (abstractControl && abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }
  // tslint:disable:no-string-literal
  if (abstractControl && abstractControl['controls']) {
    for (const controlName in abstractControl['controls']) {
      if (abstractControl['controls'][controlName]) {
        if (hasRequiredField(abstractControl['controls'][controlName])) {
          return true;
        }
      }
    }
  }
  // tslint:enable:no-string-literal
  return false;
};
