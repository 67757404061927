import { Input, HostBinding, Self, Optional, HostListener, OnChanges, SimpleChanges, Directive } from '@angular/core';
import { ControlValueAccessor, NgControl, AbstractControl } from '@angular/forms';
import { hasRequiredField } from '../../utils/forms.utils';

@Directive()
export abstract class CpaInputBaseComponentDirective implements ControlValueAccessor {
  @Input() label: string;
  @Input() helpText: string;

  private maximum: number;

  get max(): number {
    return this.maximum;
  }

  @Input()
  set max(value: number) {
    if (value > 0) {
      this.maximum = value;
    }
  }

  @HostBinding('class.read-only')
  @Input() readOnly: boolean;

  @HostBinding('class.active')
  active: boolean;

  private isDisabled: boolean;

  @HostBinding('class.disabled')
  @Input() get disabled(): boolean {
    return this.readOnly || this.isDisabled;
  }

  set disabled(value: boolean) {
    this.isDisabled = value;
  }

  constructor(
    @Self()
    @Optional()
    public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  abstract writeValue(value: any): void;
  abstract registerOnChange(onChange: (checked: boolean) => void): void;
  abstract registerOnTouched(onTouched: () => {}): void; // eslint-disable-line
  abstract length(): number;

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  @HostBinding('class.empty')
  get controlIsEmpty(): boolean {
    return this.length() <= 0;
  }

  @HostBinding('class.required')
  get controlIsRequired() {
    return this.ngControl ? hasRequiredField(this.ngControl.control) : false;
  }

  @HostBinding('class.error')
  get controlHasError(): boolean {
    return this.ngControl ? this.ngControl.invalid && (this.ngControl.touched || this.ngControl.dirty) : false;
  }
}
