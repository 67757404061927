<div *ngIf="enablePlatformNotification">
  <ngb-toast *ngFor="let bulletin of applicationBulletins; let i = index;" (hidden)="closeApplicationBulletin(i)"
    class="application {{ getSafeType(bulletin.type) }}" [autohide]="bulletin.autoHide" [delay]="bulletin.delay">
    {{bulletin.message}}
    <button aria-label="Close" (click)="closeApplicationBulletin(i)" class="close" type="button"><span
        aria-hidden="true">×</span></button>
  </ngb-toast>
</div>

<ngb-toast *ngFor="let bulletin of bulletins; let i = index;" [@inOutAnimation] 
  class="hidden-close-button {{ getSafeType(bulletin.type) }}" [class.slim]="!bulletin.body"
  [autohide]="bulletin.autoHide"  (hidden)="close(i)" [delay]="bulletin.delay" #toastr>

  <ng-template ngbToastHeader>
    <div class="d-flex w-100">
      <div class="d-flex flex-grow-1 header-label">
        <fa-icon [icon]="getIcon(bulletin.type)" [fixedWidth]="true"></fa-icon>
        <h4 class="flex-grow-1" [title]="bulletin.title">{{bulletin.title}}</h4>
      </div>
      <fa-icon class="trailing-icon" [icon]="faTimes" (click)="close(i)"></fa-icon>
    </div>
  </ng-template>
  {{bulletin.body}}
</ngb-toast>
