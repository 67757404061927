import { Injectable } from '@angular/core';

@Injectable()
export class HtmlStripperUtil {
    constructor() {}

    public stripHtml(text: string, allowBold = false): string {
        if (allowBold) {
            return text ? String(text).replace(/<(?!\/?b>|\/?strong>)[^>]+>/gi, '') : '';
        }
        return text ? String(text).replace(/<[^>]+>/gi, '') : '';
    }
}
