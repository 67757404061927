<form [formGroup]="form">
  <div class="input-group-textarea form-group" [ngClass]="{'--error': hasError(), '--readonly': readonly, '--disabled': calculatedDisabledState}">
    <cpa-helptext 
        *ngIf="helpText.length > 0"      
        [ngClass]="{'isDisabled': calculatedDisabledState}">{{helpText}}</cpa-helptext>

    <textarea 
      id="{{textAreaId}}" 
      placeholder="{{textAreaPlaceHolder}}" 
      rows="{{textAreaRows}}"
      cols="{{textAreaCols}}" 
      [formControlName]="formFieldName"      
      [readonly]="readonly || disabled"      
      [disabled]="calculatedDisabledState"></textarea>

    <label id="{{textAreaLabelId}}" *ngIf="label.length > 0">
      <cpa-h5>{{label}} <span *ngIf="isRequired()">*</span></cpa-h5>
    </label>
  </div>
</form>
