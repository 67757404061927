import {
  Component,
  forwardRef,
  HostBinding,
  HostListener,
  Input
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'cpa-toggle',
  templateUrl: './cpa-toggle.component.html',
  styleUrls: ['./cpa-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CpaToggleComponent),
      multi: true
    }
  ],
})
export class CpaToggleComponent implements ControlValueAccessor {
  @HostBinding('class.on')
  checked: boolean;

  @HostBinding('class.disabled')
  @Input()
  disabled: boolean;

  constructor() {}

  onChange = (checked: boolean) => {};

  onTouched = () => {};

  get value(): boolean {
    return this.checked;
  }

  @HostListener('click')
  toggle() {
    if (!this.disabled) {
      this.writeValue(!this.checked);
    }
  }

  writeValue(checked: boolean): void {
    this.checked = checked;
    this.onChange(this.value);
  }

  registerOnChange(onChange: (checked: boolean) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => {}): void { // eslint-disable-line
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
