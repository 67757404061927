import { FormBuilder, FormGroup } from '@angular/forms';
import { TemplateRef } from '@angular/core';

export class CpaFile<T = any> {
  constructor(file?: File) {
    this.rawFile = file;
    this.name = file?.name;
    this.type = file?.type;
    this.size = file?.size;
  }
  id?: string;
  rawFile?: File;
  name?: string;
  type?: string;
  size?: number;
  src?: string;
  details?: string;
  thumbnailSrc?: string;
  downloadLink?: string;
  error?: string;
  progress?: number;
  context?: T;

  static toFormGroup(file: CpaFile, formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      rawFile: [file.rawFile, []],
      name: [file.name, []],
      details: [file.details, []],
      size: [file.size, []],
      src: [file.src, []],
      thumbnailSrc: [file.thumbnailSrc, []],
      error: [file.error, []],
      progress: [file.progress, []],
      type: [file.type, []],
      downloadLink: [file.downloadLink, []],
    });
  }
}

export interface CpaFileConfig {
  previews?: boolean;
}

export interface CpaFileTemplates {
  thumbnailTemplate?: TemplateRef<any>;
  tooltipTemplate?: TemplateRef<any>;
  detailsTemplate?: TemplateRef<any>;
  overlayPanelTemplate?: TemplateRef<any>;
}
