import { NgModule } from '@angular/core';
import { CpaFaIconComponent } from './cpa-fa-icon.component';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
  ],
  declarations: [
    CpaFaIconComponent,
  ],
  exports: [
    CpaFaIconComponent,
  ]
})
export class CpaFaIconModule {}
