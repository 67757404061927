<ng-content></ng-content>
<div *ngIf="loading" class="loading-sheet"
    [@enterLeaveFadeAnimation]="{ value: ':enter', params: { enterDuration: enterDuration } }"
    (click)="$event.stopPropagation()"></div>
<div *ngIf="loading" class="loading-dots-container">
    <div *ngIf="loading" class="loading-dots" @enterLeaveScaleAnimation>
        <div class="loading-dot"></div>
        <div class="loading-dot"></div>
        <div class="loading-dot"></div>
    </div>
</div>