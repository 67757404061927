import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cpa-h5',
  templateUrl: './cpa-h5.component.html',
  styleUrls: ['./cpa-h5.component.scss'],
})
export class CpaH5Component {
  @Input() public additionalClasses: string;
}
