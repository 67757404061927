import { CpaTrashIconComponent } from '../icons/cpa-trash-icon/cpa-trash-icon.component';
import { CpaFilePreviewItemComponent } from './cpa-file-preview-container/cpa-file-preview-item/cpa-file-preview-item.component';
import { CpaFilePreviewContainerComponent } from './cpa-file-preview-container/cpa-file-preview-container.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CpaFilePickerComponent } from './cpa-file-picker.component';
import { CommonModule } from '@angular/common';
import { FilePickerService } from './file-picker.service';
import { CpaFileDropModule } from './cpa-file-drop/cpa-file-drop.module';
import { CpaPreviewLightboxComponent } from './cpa-file-preview-container/cpa-preview-lightbox/cpa-preview-lightbox.component';
import { CpaFileSuccessIconComponent } from '../icons/cpa-file-success-icon/cpa-file-success-icon.component';
import { CpaExclamationTriangleIconComponent } from '../icons/cpa-exclamation-triangle-icon/cpa-exclamation-triangle-icon.component';
import { CpaSadTearComponent } from '../icons/cpa-sad-tear/cpa-sad-tear.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    CpaFileDropModule,
    FontAwesomeModule,
  ],
  declarations: [
    CpaFilePickerComponent,
    CpaFilePreviewContainerComponent,
    CpaFilePreviewItemComponent,
    CpaPreviewLightboxComponent,
    CpaTrashIconComponent,
    CpaFileSuccessIconComponent,
    CpaExclamationTriangleIconComponent,
    CpaSadTearComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  exports: [CpaFilePickerComponent],
  providers: [FilePickerService]
})
export class FilePickerModule {}
