import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { IApplication } from '../../api/platform-api.service';
import { faBorderAll } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'cpa-apps',
    styleUrls: ['./apps.component.scss'],
    templateUrl: './apps.component.html',
})
export class AppsComponent implements OnChanges {
    public faBorderAll = faBorderAll;
    public faChevronRight = faChevronRight;

    @Input() public readonly coreHome: string;
    @Input() public readonly apps: IApplication[];
    public installedApps: IApplication[] = [];

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.apps) {
            return;
        }

        this.installedApps = changes.apps.currentValue
          .filter((app: IApplication) => app.manifest.availability && app.manifest.availability.myWorkspace)
            .slice(0, 10);
    }
}
