import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpaPopperComponent } from '../cpa-popper/cpa-popper.component';
import { DirectivesModule } from '../directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule
  ],
  declarations: [
    CpaPopperComponent
  ],
  exports: [
    CpaPopperComponent
  ]
})
export class CpaPopperModule {}
