import { NgModule } from '@angular/core';
import { CpaOverlayDirective } from './cpa-overlay.directive';
import { CpaOverlayComponent } from './cpa-overlay.component';
import { CommonModule } from '@angular/common';
import { CpaOverlayTestComponent } from './cpa-overlay-test/cpa-overlay-test/cpa-overlay-test.component';

@NgModule({
    declarations: [
        CpaOverlayDirective,
        CpaOverlayComponent,
        CpaOverlayTestComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        CpaOverlayDirective
    ]
})
export class CpaOverlayModule {}
