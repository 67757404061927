<label
[ngClass]="{'labelHeaderText':!disabled,
'labelHeaderTextDisabled':disabled || (readonly && !options),
'labelHeaderTextFocused':overlayVisible || focused}"
*ngIf="!!labelHeaderText">{{labelHeaderText}}</label>
<div #container [ngClass]="{'ui-dropdown ui-widget ui-state-default ui-corner-all ui-helper-clearfix':true,
            'ui-state-disabled':disabled, 'ui-state-readonly':readonly, 'ui-state-readonly-no-data':readonly && !options, 'ui-dropdown-open':overlayVisible,
            'ui-state-focus':focused, 'ui-dropdown-clearable': showClear && !disabled, 'ui-inputtext-edited':selectedOption !== null}"
            (click)="onMouseclick($event)" [ngStyle]="style" [class]="styleClass">
            <div class="ui-helper-hidden-accessible">
                <input #in [attr.id]="inputId" type="text" [attr.aria-label]="selectedOption ? selectedOption.label : ' '" readonly (focus)="onInputFocus($event)" aria-haspopup="listbox"
                    aria-haspopup="listbox" [attr.aria-expanded]="overlayVisible" [attr.aria-labelledby]="ariaLabelledBy" (blur)="onInputBlur($event)" (keydown)="onKeydown($event, true)"
                    [disabled]="disabled" [attr.tabindex]="tabindex" [attr.autofocus]="autofocus">
            </div>
            <div class="ui-helper-hidden-accessible ui-dropdown-hidden-select">
                <select [attr.required]="required" [attr.name]="name" tabindex="-1" aria-hidden="true">
                    <option *ngIf="placeholder" value="">{{placeholder}}</option>
                    <option *ngIf="selectedOption" [value]="selectedOption.value" [selected]="true">{{selectedOption.label}}</option>
                </select>
            </div>
            <div *ngIf="!multiple" class="ui-dropdown-label-container" [pTooltip]="tooltip" [tooltipPosition]="tooltipPosition" [positionStyle]="tooltipPositionStyle" [tooltipStyleClass]="tooltipStyleClass">
              <fa-icon [icon]="faTimes" class="ui-dropdown-clear-icon" (click)="clear($event)" *ngIf="value !== null && showClear && !disabled"></fa-icon>
              <label [ngClass]="{'ui-dropdown-label ui-inputtext ui-corner-all':true,'ui-dropdown-label-empty':(label === null || label?.length === 0)}" *ngIf="!editable && (label !== null)">
                    <ng-container *ngTemplateOutlet="selectedItemTemplate; context: {$implicit: label}"></ng-container>
              </label>
              <label [ngClass]="{'ui-dropdown-label ui-inputtext ui-corner-all ui-placeholder':true,'ui-dropdown-label-empty': (placeholder === null || placeholder?.length === 0)}" *ngIf="!editable && (label === null)">{{placeholder||'empty'}}</label>
              <input #editableInput type="text" [attr.maxlength]="maxlength" [attr.aria-label]="selectedOption ? selectedOption.label : ' '" class="ui-dropdown-label ui-inputtext ui-corner-all" *ngIf="editable" [disabled]="disabled" [attr.placeholder]="placeholder"
                  aria-haspopup="listbox" [attr.aria-expanded]="overlayVisible" (click)="onEditableInputClick($event)" (input)="onEditableInputChange($event)" (focus)="onEditableInputFocus($event)" (blur)="onInputBlur($event)">
            </div>

            <ul *ngIf="multiple" #multiContainer class="ui-dropdown-multiple-container ui-widget ui-state-default ui-corner-all" [ngClass]="{'ui-state-disabled':disabled, 'ui-state-readonly': readonly, 'ui-state-focus':focused}">
                <li *ngIf="!selectedOptions">
                    <span class="multiple-label">{{label}}</span>
                </li>
                <li #token *ngFor="let option of selectedOptions?.slice(0, 2)" class="ui-dropdown-multiple-token ui-state-highlight">
                    <fa-icon class="ui-dropdown-multiple-token-icon" [icon]="faTimes" (click)="removeListItemMultiSelection($event, option)" *ngIf="!disabled && !readonly"></fa-icon>
                    <span *ngIf="!selectedItemTemplate" class="ui-dropdown-multiple-token-label">{{option.label}}</span>
                    <ng-container *ngTemplateOutlet="selectedItemTemplate; context: {$implicit: option.label}"></ng-container>
                </li>
                <li
                *ngIf="selectedOptionsCount > 2"
                class="ui-dropdown-multiple-token-count ui-state-count-highlight"
                (mouseenter)="createChipSelectedOptionsList()"
                [cpaOverlay]="chipTooltipContainer"
                [tooltipPosition]="chipTooltipPosition"
                >
                    <span>+ {{selectedOptionsCount - 2}}</span>
                </li>
            </ul>

            <ng-template #chipTooltipContainer>
                <div class="chip-tooltip-container">
                    <span>{{chipSelectedOptionsList}}</span>
                </div>
            </ng-template>

            <div [ngClass]="{'ui-dropdown-trigger ui-state-default ui-corner-right': true}" role="button" aria-haspopup="listbox" [attr.aria-expanded]="overlayVisible">
                <fa-icon class="ui-dropdown-trigger-icon ui-clickable" [icon]="dropDownTriggerIcon"></fa-icon>
            </div>
            <div *ngIf="overlayVisible" class="cpa-select-overlay ui-dropdown-panel ui-widget ui-widget-content ui-corner-all ui-shadow" [class]="panelStyleClass" [ngStyle]="panelStyle" [@overlayAnimation]="{value: 'visible', params: {showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions}}" (@overlayAnimation.start)="onOverlayAnimationStart($event)">
                <div *ngIf="filter" class="ui-dropdown-filter-container" (click)="$event.stopPropagation()">
                    <cpa-text-input
                        #filter
                        autocomplete="off"
                        [value]="filterValue||''"
                        [placeholder]="filterPlaceholder"
                        (keydown.enter)="$event.preventDefault()"
                        (keydown)="onKeydown($event, false)"
                        (input)="onFilter($event)"
                        [attr.aria-label]="ariaFilterLabel"
                        [label]="filterHeaderText"
                        [icon]="faFilter"></cpa-text-input>
                    <span class="ui-dropdown-filter-icon pi pi-search"></span>
                </div>
                <div *ngIf="showPagination" class="current-page-report">
                  SHOWING {{ paginationOffset + 1 }} - {{ paginationOffset + dropdownOptions?.length }} OF {{ paginationOptions.totalRecords }} RESULTS
                </div>
                <div class="ui-dropdown-items-wrapper" [style.max-height]="virtualScroll ? 'auto' : (scrollHeight||'auto')">
                    <ul class="ui-dropdown-items ui-dropdown-list ui-widget-content ui-widget ui-corner-all ui-helper-reset" role="listbox">
                      <ng-container *ngIf="group">
                          <ng-template ngFor let-optgroup [ngForOf]="optionsToDisplay">
                              <li class="ui-dropdown-item-group">
                                <div *ngIf="optgroup.label && optgroup.items?.length > 0" class="dropdownItemsLabel" (click)="$event.stopPropagation()">
                                    <cpa-h5>{{optgroup.label}}</cpa-h5>
                                </div>
                              </li>
                              <ng-container *ngTemplateOutlet="itemslist; context: {$implicit: optgroup.items, selectedOption: selectedOption}"></ng-container>
                          </ng-template>
                      </ng-container>
                      <ng-container *ngIf="!group">
                          <ng-container *ngTemplateOutlet="itemslist; context: {$implicit: optionsToDisplay, selectedOption: selectedOption}"></ng-container>
                      </ng-container>
                      <ng-template #itemslist let-options let-selectedOption="selectedOption">
                          <ng-container *ngIf="!virtualScroll; else virtualScrollList">
                              <ng-template ngFor let-option [ngForOf]="options">
                                  <cpa-select-item
                                                  [option]="option"
                                                  [selected]="isItemSelected(option)"
                                                  [hovered]="hoveredOption === option"
                                                  [hoveredSelected]="isItemSelected(hoveredOption) && hoveredOption === option"
                                                  (dropdownItemClick)="onItemClick($event)"
                                                  (dropdownItemHovered)="onDropdownItemHovered($event)"
                                                  (dropdownItemRemoved)="onDropdownItemRemoved($event)"
                                                  [template]="itemTemplate"
                                                  [iconType]="iconType"
                                                  [icon]="icon"
                                                  [multiSelect]="multiple"></cpa-select-item>
                              </ng-template>
                          </ng-container>
                          <ng-template #virtualScrollList>
                              <cdk-virtual-scroll-viewport (scrolledIndexChange)="scrollToSelectedVirtualScrollElement()" #viewport [ngStyle]="{'height': scrollHeight}" [itemSize]="itemSize" *ngIf="virtualScroll && optionsToDisplay && optionsToDisplay?.length">
                                  <ng-container *cdkVirtualFor="let option of options; let i = index; let c = count; let f = first; let l = last; let e = even; let o = odd">
                                      <cpa-select-item [option]="option" [selected]="selectedOption === option"
                                                                  (dropdownItemClick)="onItemClick($event)"
                                                                  [template]="itemTemplate"></cpa-select-item>
                                  </ng-container>
                              </cdk-virtual-scroll-viewport>
                          </ng-template>
                      </ng-template>
                      <li *ngIf="filter && (!optionsToDisplay || (optionsToDisplay && optionsToDisplay?.length === 0))" class="ui-dropdown-empty-message">{{emptyFilterMessage}}</li>
                    </ul>
                </div>
                <div *ngIf="showPagination" class="pagination-container">
                  <p-paginator
                    [totalRecords]="paginationOptions.totalRecords"
                    [first]="paginationOffset"
                    [rows]="paginationOptions.rowsPerPage"
                    [rowsPerPageOptions]="paginationOptions.rowsPerPageOptions || [10, 20, 50, 100]"
                    (onPageChange)="onPageChange($event)">
                  </p-paginator>
                </div>
            </div>
        </div>
        <cpa-helptext
        *ngIf="!!helptext"
        [ngClass]="{'isDisabled': disabled || (readonly && !options)}">{{helptext}}</cpa-helptext>
