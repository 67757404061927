import { Component, OnInit, Input, HostBinding, HostListener } from '@angular/core';

@Component({
  selector: 'cpa-input',
  templateUrl: './cpa-input.component.html',
  styleUrls: ['./cpa-input.component.scss'],
})
export class CpaInputComponent implements OnInit {
  @Input() label: string;
  @Input() helpText: string;
  @Input() max: number;
  @Input() length: number;

  constructor() { }

  ngOnInit() {
  }
}
