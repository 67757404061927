<div class="form-group">
    <div class="datepicker-input">
        <label *ngIf="!!label">
            <cpa-h5>{{label}}</cpa-h5>
        </label>
        <input name="dp"
        class="form-control"
        ngbDatepicker
        #d="ngbDatepicker"
        [autoClose]="autoClose"
        [placeholder]="placeholder"
        [footerTemplate]="footerTemplate"
        [minDate]="minDate"
        [maxDate]="maxDate"
        (click)="openDatepicker(d)"
        (blur)="onTouched()"
        [ngModel]="value"
        [disabled]="calculatedDisabledState"
        [autocomplete]="autocomplete"
        [markDisabled]="markDisabled"
        [readonly]="readonly || calculatedDisabledState"
        (ngModelChange)="writeValue($event)"
        (keydown)="onKeydown($event)"
        [container]="container"/>
        <fa-icon class="dp-icon" [icon]="faCalendar"></fa-icon>
    </div>
    <cpa-helptext *ngIf="!!helpText">{{helpText}}</cpa-helptext>
</div>
<ng-template #footerTemplate>
    <div class="footer d-flex cpa-mt-3 cpa-mr-2 cpa-mb-3 cpa-ml-2">
      <cpa-button
        class="reset-button"
        type="primary"
        label="Reset"
        [outlined]="true"
        (click)="onReset()">
      </cpa-button>
      <cpa-button
        class="select-button"
        type="primary"
        label="Select date"
        [disabled]="!hasFormValue()"
        (click)="onSelectDate(d)"></cpa-button>
    </div>
</ng-template>