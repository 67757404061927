import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cpa-card',
  templateUrl: './cpa-card.component.html',
  styleUrls: ['./cpa-card.component.scss'],
})
export class CpaCardComponent implements OnInit {

  constructor() { }
  @Input() public tag: string;
  @Input() public title: string;
  @Input() public description: string;
  @Input() public additionalClasses: string;

  ngOnInit() {
  }

}
