import { Component, Output, EventEmitter, Input, HostBinding } from '@angular/core';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'cpa-button',
  templateUrl: './cpa-button.component.html',
  styleUrls: ['./cpa-button.component.scss'],
})
export class CpaButtonComponent {

  @HostBinding('class.display-as-block')
  @Input() block = false;


  @Input() type: 'primary' | 'secondary' | 'danger' | 'info' | 'warning' | 'success' = 'primary';
  @Input() outlined = false;
  @Input() disabled = false;

  @Input() iconType: 'fas' | 'far' | 'fal' | 'fad' | 'fab' = 'far';
  @Input() icon: string | IconDefinition;

  @Input() small = false;

  @Input() label: string;

  @Output() clickEvent: EventEmitter<any> = new EventEmitter();

  click($event: any) {
    if (!this.disabled) {
      this.clickEvent.emit($event);
    }
  }

  get isIconTypeString(): boolean {
    return !!this.icon && !this.isIconTypeIconDefinition;
  }

  get isIconTypeIconDefinition(): boolean {
    const iconAny = this.icon as any;
    return (!!iconAny && !!iconAny.iconName);
  }

}
