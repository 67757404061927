import { Subscription } from 'rxjs';
import { Component, AfterContentInit, ContentChild, OnDestroy } from '@angular/core';
import { CpaSideMenuComponent } from '../cpa-side-menu/side-menu.component';

@Component({
  selector: 'cpa-main',
  styleUrls: ['./main.component.scss'],
  templateUrl: './main.component.html',
})
export class CpaMainComponent implements AfterContentInit, OnDestroy {
  @ContentChild(CpaSideMenuComponent) sideMenu: CpaSideMenuComponent;

  collapsed: boolean;

  private sideMenuSubscription: Subscription;

  ngAfterContentInit(): void {
    this.sideMenuSubscription = this.sideMenu.collapsedChanged.subscribe(collapsed => {
      this.collapsed = collapsed;
    });
  }

  ngOnDestroy(): void {
    this.sideMenuSubscription.unsubscribe();
  }
}
