<div class="d-flex justify-content-center drop-zone"
      [ngClass]="{ 'hovering-files': hoveringFiles }"
      cpaDragAndDrop
      (dropFiles)="onFilesDropped($event); hoveringFiles = false"
      (dragFilesInside)="hoveringFiles = true"
      (dragFilesOutside)="hoveringFiles = false">
  <div>
    <fa-icon [icon]="faCloudUpload"></fa-icon>
    <ng-container *ngTemplateOutlet="config?.captionTemplate || defaultCaptionTemplate;context:{ fileInput: fileInput }"></ng-container>
  </div>
</div>
<input
  #fileInput
  type="file"
  (click)="fileInput.value = null"
  [accept]="config?.accept"
  (change)="onFilesBrowsed($event)">

<ng-template #defaultCaptionTemplate>
  <span>Drop files here or <a class="cpa-semibold" (click)="fileInput.click()" href="javascript:void(0)"><u>browse</u></a> for a file to upload.</span>
</ng-template>
