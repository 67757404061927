import { Component, Input, EventEmitter, ContentChildren, QueryList, AfterContentInit, HostListener, ElementRef } from '@angular/core';
import { faChevronDown, faChevronRight, IconDefinition } from '@fortawesome/pro-regular-svg-icons';

export type CpaSideMenuItemType = 'default' | 'nested';

@Component({
  selector: 'cpa-side-menu-item',
  styleUrls: ['./side-menu-item.component.scss'],
  templateUrl: './side-menu-item.component.html',
})
export class CpaSideMenuItemComponent implements AfterContentInit {

  constructor(private element: ElementRef) {
  }

  badgeValue: number;
  badgeNumber: string;

  @Input() set badge(value: number) {
    this.badgeValue = value;
    this.badgeNumber = this.getBadgeNumber(this.badgeValue);
  }

  isOpen: boolean;

  @Input() set open(value: boolean) {
    this.isOpen = value;
    this.trailingIcon = this.isOpen ? faChevronDown : faChevronRight;
    this.updateSubItemsViewHeight();
  }

  @Input() collapsed: boolean;
  @Input() icon: string | IconDefinition;
  @Input() label: string;
  @Input() href: string;
  @Input() selected: boolean;
  @Input() itemHeightPx = 48;

  clicked = new EventEmitter<CpaSideMenuItemComponent>();
  parent: CpaSideMenuItemComponent;
  children: CpaSideMenuItemComponent[];

  @ContentChildren(CpaSideMenuItemComponent) private childSideMenuItems !: QueryList<CpaSideMenuItemComponent>;

  popoutOffsetTop: number;
  popoutOffsetLeft: number;
  popoutArrowOffsetTopPx: string;
  subItemsViewHeight: string;
  subItemsHeightPxs: number;
  trailingIcon: IconDefinition = faChevronRight;

  @HostListener('mouseenter')
  onMouseOver() {
    if (this.collapsed) {
      this.updatePopoutOffsets(this.element.nativeElement);
    }
  }

  ngAfterContentInit(): void {
    this.updateChildren();
    this.childSideMenuItems.changes.subscribe(() => {
      this.updateChildren();
    });
  }

  private updateChildren() {
    /* filter(item => item !== this) is due to a bug in Angular where it recognizes itself as a child.
       (https://github.com/angular/angular/issues/10098#issuecomment-235157642) */
    this.children = this.childSideMenuItems.filter(item => item !== this);
    this.children.forEach(item => {
      item.parent = this;
    });
    this.updateSubItemsHeight();
  }

  toggleOpen(event) {
    if (!this.collapsed) {
      this.open = !this.isOpen;
      event.stopPropagation();
    }
  }

  onClick() {
    this.clicked.emit(this);
  }

  private getBadgeNumber(value: number): string {
    const clampedValue = Math.max(0, Math.min(99, value));
    return `${clampedValue}${clampedValue === 99 ? '+' : ''}`;
  }

  private updateSubItemsViewHeight() {
    this.subItemsViewHeight = `${this.isOpen ? this.subItemsHeightPxs : 0}px`;
  }

  private updateSubItemsHeight() {
    this.subItemsHeightPxs = this.children.length * this.itemHeightPx;
    this.updateSubItemsViewHeight();
  }

  private updatePopoutOffsets(nativeElement: any) {
    let offsetTop = 0;
    let offsetLeft = 0;

    while (nativeElement) {
      if (!isNaN(nativeElement.offsetTop)) {
        offsetTop += nativeElement.offsetTop - nativeElement.scrollTop;
      }
      if (!isNaN(nativeElement.offsetLeft)) {
        offsetLeft += nativeElement.offsetLeft;
      }
      nativeElement = nativeElement.offsetParent;
    }

    const minArrowOffsetTop = this.itemHeightPx / 2;
    const maxArrowOffsetTop = this.subItemsHeightPxs - this.itemHeightPx;
    const maxArrowShiftFromMin = maxArrowOffsetTop - minArrowOffsetTop;
    const popoutBottom = offsetTop + this.subItemsHeightPxs;
    const windowPadding = 32;
    const amountOutsideScreen = popoutBottom - window.innerHeight + windowPadding;

    let arrowOffsetTop = minArrowOffsetTop;

    if (amountOutsideScreen > 0) {
      const shift = Math.min(maxArrowShiftFromMin, amountOutsideScreen);
      arrowOffsetTop += shift;
      offsetTop -= shift;
    }

    this.popoutOffsetTop = offsetTop;
    this.popoutOffsetLeft = offsetLeft;
    this.popoutArrowOffsetTopPx = `${arrowOffsetTop}px`;

    // Add extra offset to top as it appears to wander off a bit on Edge and IE11
    // Well, don't look at me, I don't know what to tell you
    if (/Edge\/\d./i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) {
      this.popoutOffsetTop -= 30;
    }
  }
}
