import { CpaFileMimeTypeResolver } from './cpa-file-mime-type-resolver';
import { Observable, Subject, of, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';

export interface CpaFileSrcGenerator {
  generateSrc(file: File): Observable<string>;
}

export class CpaDefaultFileSrcGenerator implements CpaFileSrcGenerator {
  constructor(private mimeTypeResolver: CpaFileMimeTypeResolver) {
  }

  generateSrc(file: File): Observable<string> {
    const mimeType = this.mimeTypeResolver.resolve(file);
    const returnSubject = new ReplaySubject<string>();
    const returnObservable = returnSubject.pipe(first());
    if (mimeType.startsWith('image')) {
      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          returnSubject.next(reader.result as string);
        };
      } catch (e) {
        returnSubject.next(null);
      }
    } else {
      returnSubject.next(null);
    }
    return returnObservable;
  }
}
