import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'arrayJoin',
  pure: true
})
export class ArrayJoinPipe implements PipeTransform {
  constructor() {
  }

  transform(objects: any[], delimiter: string): any {
    return objects.filter(obj => !!obj).join(delimiter);
  }
}
