import { Component, Input, Output, EventEmitter, HostBinding } from '@angular/core';

@Component({
  selector: 'cpa-side-menu-header',
  styleUrls: ['./side-menu-header.component.scss'],
  templateUrl: './side-menu-header.component.html',
})
export class CpaSideMenuHeaderComponent {
  @HostBinding('class.collapsed')
  @Input() collapsed: boolean;
  @Input() label: string;
}
