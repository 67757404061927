import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { CpaMath } from '../../utils/math.utils';

@Component({
  selector: 'cpa-file-progress-bar',
  templateUrl: './cpa-file-progress-bar.component.html',
  styleUrls: ['./cpa-file-progress-bar.component.scss']
})
export class CpaFileProgressBarComponent implements OnInit, AfterViewInit {
  faTimes = faTimes;

  _progress = 0;
  @Input('progress') set progress(progress: number) {
    this._progress = CpaMath.clamp(progress, 0, 100);
  }
  get progress(): number {
    return this.viewInitialized ? this._progress : 0;
  }

  @Output() cancel = new EventEmitter<void>();

  viewInitialized: boolean;

  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewInitialized = true;
      this.changeDetector.detectChanges();
    }, 1);
  }
}
