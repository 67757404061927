import { Component, OnInit, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

/**
 * Provides an atom wrapper for fa-icon that keeps it centered
 * in a box of 20 by 20 pixels. Makes it easier to follow designs.
 */
@Component({
  selector: 'cpa-fa-icon',
  templateUrl: './cpa-fa-icon.component.html',
  styleUrls: ['./cpa-fa-icon.component.scss'],
})
export class CpaFaIconComponent implements OnInit {
  @Input() icon: IconDefinition;

  constructor() { }

  ngOnInit(): void {
  }
}
