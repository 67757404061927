import {
  Component,
  OnInit,
  Input,
  Optional,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { CpaInputBaseComponentDirective } from '../internal/cpa-input/cpa-input.base.component';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'cpa-choice-chips',
  templateUrl: './cpa-choice-chips.component.html',
  styleUrls: ['./cpa-choice-chips.component.scss'],
})
export class CpaChoiceChipsComponent extends CpaInputBaseComponentDirective implements OnInit {
  public faPlus = faPlus;

  @Input() choices: any[];
  @Input() labelField: string;
  @Input() keyField: string;
  @Input() readOnlyField: string;
  @Input() selectAllToggle: boolean;

  objectKeys = Object.keys;
  fullSelection: [] = [];
  selection: { [key: string]: any } = {};
  allSelected: boolean;

  constructor(
    @Self()
    @Optional()
    ngControl: NgControl
  ) {
    super(ngControl);
  }

  get value(): any {
    const newFullSelection = [];
    if (this.fullSelection) {
      this.fullSelection.map(
        selected => {
          if (!this.choices.find(choice => this.getKey(choice) === this.getKey(selected))) {
            newFullSelection.push(selected);
          }
        });
    }
    return newFullSelection.concat(Object.values(this.selection));
  }

  set value(selectedChoices: any) {
    this.fullSelection = selectedChoices;
    this.selection = {};
    if (selectedChoices && this.choices) {
      selectedChoices.filter(selectedChoice => this.choices.find(choice => this.getKey(choice) === this.getKey(selectedChoice)))
        .map(selectedChoice => this.selection[this.getKey(selectedChoice)] = selectedChoice);
    }
    this.refresh();
  }

  onChange = (selectedChoices: any) => { };
  onTouched = () => { };

  ngOnInit() {
  }

  writeValue(value: any): void {
    if (this.value !== value) {
      this.value = value;
    }
  }

  registerOnChange(onChange: (selectedChoices: any) => void): void {
    this.onChange = (selectedChoices: any) => {
      onChange(selectedChoices);
      this.ngControl.control.patchValue(this.ngControl.control.value);
    };
  }

  registerOnTouched(onTouched: () => {}): void { // eslint-disable-line
    this.onTouched = onTouched;
  }

  length(): number {
    return Object.values(this.selection).length;
  }

  toggle(key: string) {
    if (!this.disabled) {
      if (this.readOnlyField) {
        const item = this.choices.find(choice => this.getKey(choice) === key);
        if (item && item[this.readOnlyField]) { return; }
      }
      if (this.selection[key]) {
        delete this.selection[key];
      } else {
        this.selection[key] = this.choices.find(choice => this.getKey(choice) === key);
      }
      this.onChange(this.value);
      this.refresh();
    }
  }

  onSelectAllToggle() {
    if (!this.disabled) {
      if (Object.values(this.selection).length !== this.choices.length) {
        this.choices.map(choice => this.selection[this.getKey(choice)] = choice);
      } else {
        this.selection = {};
      }
      this.onChange(this.value);
      this.refresh();
    }
  }

  private getKey(choice: any): any {
    return choice[this.keyField] || choice;
  }

  private refresh() {
    this.allSelected = this.choices && this.choices.every(choice => !!this.selection[this.getKey(choice)]);
  }
}
