import {Directive, Output, EventEmitter, OnInit, AfterViewInit} from '@angular/core';

@Directive({
  selector: '[cpaOnInit]'
})
export class CpaOnInitDirective implements OnInit, AfterViewInit {
  @Output() public init = new EventEmitter<void>();
  @Output() public viewInit = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
    this.init.emit();
  }

  ngAfterViewInit(): void {
    this.viewInit.emit();
  }
}
