import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { faCloudUpload } from '@fortawesome/pro-regular-svg-icons';
import { CpaFile } from '../cpa-file/cpa-file.models';
import { CpaFileSrcGenerator, CpaDefaultFileSrcGenerator } from './cpa-file-src-generator';
import { CpaFileMimeTypeResolver, CpaDefaultMimeTypeResolver } from './cpa-file-mime-type-resolver';
import { Observable, forkJoin, ReplaySubject } from 'rxjs';
import { first, finalize } from 'rxjs/operators';
import { CpaFileDropZoneConfig } from './cpa-file-drop-zone.models';

@Component({
  selector: 'cpa-file-drop-zone',
  templateUrl: './cpa-file-drop-zone.component.html',
  styleUrls: ['./cpa-file-drop-zone.component.scss']
})
export class CpaFileDropZoneComponent implements OnInit, OnChanges {
  faCloudUpload = faCloudUpload;
  @ViewChild('fileInput', {static: true}) fileInput: ElementRef<HTMLElement>;

  @Input() config: CpaFileDropZoneConfig;
  @Input() mimeTypeResolver: CpaFileMimeTypeResolver;
  @Input() srcGenerator: CpaFileSrcGenerator;

  @Output() files = new EventEmitter<CpaFile[]>();

  hoveringFiles: boolean;

  constructor() {
  }

  ngOnInit() {
    this.srcGenerator = this.srcGenerator || new CpaDefaultFileSrcGenerator(this.mimeTypeResolver || new CpaDefaultMimeTypeResolver());
    this.setMultipleAttribute();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.fileInput && changes.config) {
      this.setMultipleAttribute();
    }
  }

  onFilesBrowsed($event) {
    if ($event.target.files.length > 0) {
      forkJoin(this.toCpaFiles(Array.from($event.target.files))).subscribe(cpaFiles => {
        this.files.emit(cpaFiles);
      });
    }
  }

  onFilesDropped($event) {
    if ($event.dataTransfer.files.length > 0) {
      forkJoin(this.toCpaFiles(Array.from($event.dataTransfer.files))).subscribe(cpaFiles => {
        this.files.emit(cpaFiles);
      });
    }
  }

  private toCpaFiles(files: File[]): Observable<CpaFile>[] {
    return files.map(file => {
      const cpaFile = new CpaFile(file);
      const result = new ReplaySubject<CpaFile>();
      const resultObservable = result.pipe(first());
      if (this.srcGenerator) {
        this.srcGenerator.generateSrc(file).subscribe((src) => {
          cpaFile.src = src;
          result.next(cpaFile);
        });
      } else {
        result.next(cpaFile);
      }
      return resultObservable;
    });
  }

  private setMultipleAttribute() {
    if (this.config?.multiple) {
      this.fileInput.nativeElement.setAttribute('multiple', 'true');
    } else {
      this.fileInput.nativeElement.removeAttribute('multiple');
    }
  }
}
