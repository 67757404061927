export enum Position {
    TOP_CENTER = 'top-center',
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
    BOTTOM_CENTER = 'bottom-center',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
    LEFT_CENTER = 'left-center',
    LEFT_TOP = 'left-top',
    LEFT_BOTTOM = 'left-bottom',
    RIGHT_CENTER = 'right-center',
    RIGHT_TOP = 'right-top',
    RIGHT_BOTTOM = 'right-bottom'
}
