import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'readableBytes',
  pure: true
})
export class CpaReadableBytesPipe implements PipeTransform {

  constructor() {
  }

  transform(bytes: number): string {
    if (bytes > 0) {
      const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      let l = 0;
      let n = 0;
      n = bytes || 0;
      while (n >= 1024 && ++l) {
        n = n / 1024;
      }
      // include a decimal point and a tenths-place digit if presenting
      // less than ten of KB or greater units
      return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
    }
    return '';
  }
}
