<div id="dropZone"  [className]="customstyle" [class.over]="dragoverflag"
    (drop)="dropFiles($event)"
    (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">

    <div #ref class="custom-dropzone" >
      <ng-content></ng-content>
      </div>

    <div class="content" *ngIf="ref.children?.length == 0">
              <div class="content-top-text">
                <cpa-cloud-icon></cpa-cloud-icon>
                {{captions?.dropzone?.title}}
              </div>
    </div>
</div>
