import { PipeTransform, Pipe } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faImage, faFileWord, faFileExcel, faFilePdf, faFile } from '@fortawesome/pro-regular-svg-icons';
import {
  faImage as faImageSolid,
  faFileWord as faFileWordSolid,
  faFileExcel as faFileExcelSolid,
  faFilePdf as faFilePdfSolid,
  faFile as faFileSolid
} from '@fortawesome/pro-solid-svg-icons';

interface FileNameRegExpIconDefinition {
  regExp: RegExp;
  icon: IconDefinition;
  iconSolid: IconDefinition;
}

/**
 * Finds the appropriate Font Awesome icon for a file name with extension.
 * @param fileName The name of the file.
 * @param solid Whether the icon should be of the solid style.
 */
@Pipe({
  name: 'faFileIcon',
  pure: true
})
export class FontAwesomeFileIconPipe implements PipeTransform {

  private definitions: FileNameRegExpIconDefinition[] = [
    {
      regExp: /\.(gif|jpe?g|tiff|png|webp|bmp|svg)$/i,
      icon: faImage,
      iconSolid: faImageSolid
    },
    {
      regExp: /\.(doc(x|m|b)|dot(x|m))$/i,
      icon: faFileWord,
      iconSolid: faFileWordSolid
    },
    {
      regExp: /\.(xls(x|m|b)|xlt(x|m)|xl(a|am|l|w))$/i,
      icon: faFileExcel,
      iconSolid: faFileExcelSolid
    },
    {
      regExp: /\.(pdf)$/i,
      icon: faFilePdf,
      iconSolid: faFilePdfSolid
    }
  ];

  constructor() {
  }

  transform(fileName: string, solid?: boolean): IconDefinition {
    const definition = this.definitions.find(fei => fei.regExp.test(fileName));
    const icon = solid ? definition?.iconSolid : definition?.icon;
    return icon || (solid ? faFileSolid : faFile);
  }
}
