import { Component, OnInit, Input, HostBinding, Output, EventEmitter, ChangeDetectorRef, HostListener, Self,
  Optional } from '@angular/core';
import { CpaFile, CpaFileTemplates } from '../cpa-file/cpa-file.models';
import { FormControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { noop, Subject } from 'rxjs';
import { CpaFileRemoveEvent } from '../cpa-file-list/cpa-file-list.models';
import * as _ from 'lodash';
import { coerceBooleanProperty } from '../utils/input.utils';
import { hasRequiredField } from '../utils/forms.utils';
import { CpaFileUploaderConfig } from './cpa-file-uploader.models';

@Component({
  selector: 'cpa-file-uploader',
  templateUrl: './cpa-file-uploader.component.html',
  styleUrls: ['./cpa-file-uploader.component.scss']
})
export class CpaFileUploaderComponent implements ControlValueAccessor, OnInit {
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Input() config: CpaFileUploaderConfig;
  @Input() fileTemplates: CpaFileTemplates;

  @HostBinding('class.disabled') disabled: boolean;
  @Input('disabled') set setDisabled(value: boolean) { this.disabled = value; }

  _required: boolean;
  _requiredValidator: boolean;
  @HostBinding('class.required')
  get required(): boolean {
    if (hasRequiredField(this.ngControl?.control)) {
      return this.ngControl?.control.touched || this.ngControl?.control.dirty;
    }

    return this._required;
  }
  @Input('required') set setRequired(value: any) { this._required = coerceBooleanProperty(value); }

  @Output() remove = new EventEmitter<CpaFileRemoveEvent>();
  @Output() fileClick = new EventEmitter<CpaFile>();

  get filesClickable(): boolean {
    return this.fileClick.observers.length > 0;
  }

  files: CpaFile[] = [];
  get value(): CpaFile[] {
    return this.files;
  }
  set value(files: CpaFile[]) {
    if (files !== this.files) {
      this.files = files;
      this.onChangeCallback(files);
    }
  }

  constructor(
    @Self() @Optional() private ngControl: NgControl,
    private changeDetector: ChangeDetectorRef
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  onTouch() {
    this.onTouchedCallback();
  }

  ngOnInit(): void {
  }

  onFilesAdded(filesAdded: CpaFile[]) {
    filesAdded.map(file => {
      if (this.config?.fileProcessor) {
        const progressSubject = new Subject<number>();
        progressSubject.subscribe((progress) => {
          file.progress = progress;
        },
        (error) => {
          file.error = error;
          file.progress = null;
        },
        () => {
          file.progress = 100;
          setTimeout(() => {
            file.progress = null;
          }, 1);
        });
        this.config.fileProcessor(file, progressSubject);
      }
      return file;
    }).map(file => {
      const fileNames = this.files.filter(m => !m.error)
                                  .map(f => f.name)
                                  .reduce((acc, curr) => acc.concat(curr), []);

      if (!fileNames.includes(file.name)) {
        this.files.push(file);
      }
    });
    this.onChangeCallback(this.files);
  }

  writeValue(files: CpaFile[]) {
    if (!_.isEqual(_.sortBy(this.files), _.sortBy(files))) {
      this.files = [...files];
      this.changeDetector.detectChanges();
    }
  }

  registerOnChange(fn: (value: any) => void) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouchedCallback = fn;
  }

  validate(control: FormControl) {
    this._requiredValidator = hasRequiredField(control);
    if (this.files) {
      return this.files?.filter(file => !!file.error).map(file => ({ error: file.error }));
    }
    return null;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  removeFile(event: CpaFileRemoveEvent) {
    if (this.remove.observers.length > 0) {
      this.remove.emit(event);
    } else {
      this.files.splice(event.index, 1);
      this.onChangeCallback(this.files);
    }
  }
}
