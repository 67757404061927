import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../pipes/pipes.module';
import { CpaOverlayModule } from '../directives/cpa-overlay/cpa-overlay.module';
import { CpaPopperModule } from '../cpa-popper/cpa-popper.module';
import { CpaFileListComponent } from './cpa-file-list.component';
import { CpaFileModule } from '../cpa-file/cpa-file.module';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    PipesModule,
    CpaOverlayModule,
    CpaPopperModule,
    CpaFileModule
  ],
  declarations: [
    CpaFileListComponent
  ],
  exports: [
    CpaFileListComponent
  ]
})
export class CpaFileListModule {}
