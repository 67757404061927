<cpa-input class="borderless" [label]="label" [helpText]="helpText" [max]="max" [length]="length()">
  <cpa-toggle 
    *ngIf="selectAllToggle" 
    [disabled]="disabled"
    [ngModel]="allSelected"
    (click)="onSelectAllToggle()"
    controls>
  </cpa-toggle>
  <div class="chips-container">
    <div class="d-flex flex-wrap chips">
      <ng-container *ngFor="let choice of choices; let i = index">
        <cpa-chip
          *ngFor="let selectedChoice of [{ key: choice[keyField] || choice, value: selection[choice[keyField] || choice], label: choice[labelField] || choice, readOnly: choice[readOnlyField] || false }]"
          [class.unchosen-chip]="!selectedChoice.value && (disabled || selectedChoice.readOnly)"
          [icon]="selectedChoice.value || disabled || selectedChoice.readOnly ? undefined : faPlus"
          [label]="selectedChoice.label"
          [disabled]="disabled || selectedChoice.readOnly"
          [selected]="selectedChoice.value"
          (click)="toggle(selectedChoice.key); $event.stopPropagation();">
        </cpa-chip>
      </ng-container>

    </div>
  </div>
</cpa-input>