import { Component, Output, EventEmitter, Inject, OnInit, Input } from '@angular/core';
import { PLATFORM_HOME, APP_NAME, APP_LOGO } from '../../injections';
import { PlatformApiService, IApplication, INotification } from '../api/platform-api.service';
import { interval } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { HtmlStripperUtil } from '../utils/html-strip.util';

@Component({
  selector: 'cpa-topbar',
  styleUrls: ['./topbar.component.scss'],
  templateUrl: './topbar.component.html',
})
export class TopbarComponent implements OnInit {
  public apps: IApplication[] = [];
  public notifications: INotification[] = [];
  public isAdmin: boolean;
  public user: string;
  public organisation: string;
  @Input() offline: boolean;
  @Output() public readonly logout: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly settingloader: EventEmitter<void> = new EventEmitter<void>();
  currentYear: number = new Date().getFullYear();

  constructor(
    private readonly coreApiService: PlatformApiService,
    private readonly htmlStripper: HtmlStripperUtil,
    @Inject(PLATFORM_HOME) public readonly coreHome: string,
    @Inject(APP_NAME) public readonly appName: string,
    @Inject(APP_LOGO) public readonly appLogo: string) { }

  public ngOnInit(): void {
    if (!this.offline) {
      // Get user profile
      this.loadProfile();

      // Get has access to IPP Admin Area
      this.coreApiService.getHasAccessToIppAdminArea().subscribe((hasAccess) =>
        this.isAdmin = hasAccess.hasAdminAccess);

      // Get installed applications
      this.coreApiService.getInstalledApplications().subscribe((apps) => this.apps = apps);

      // Fetching notifications
      interval(1000 * 30).pipe(startWith(0)).subscribe(() => this.coreApiService.getUnreadNotifications(5)
        .subscribe((notifications) => this.notifications = notifications.map(notification => {
          notification.content = this.htmlStripper.stripHtml(notification.content, true);
          return notification;
        })));
    }
  }

  public loadProfile(): void {
    this.coreApiService.getUserProfile().subscribe((profile) => {
      this.organisation = profile.identity.organisationName;
      this.user = profile.identity.fullName;
    });
  }

  public onLogout(): void {
    this.logout.emit(null);
  }

  public loadSettings(): void {
    this.settingloader.emit(null);
  }
}
