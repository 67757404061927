import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'cpa-loading-inline',
    animations: [
        trigger('enterLeaveScaleAnimation', [
            transition(
                ':enter',
                [
                    style({ transform: 'scale(0)', opacity: '0' }),
                    animate('{{ enterDuration }} 0.5s ease', style({ transform: 'scale(1)', opacity: '1' })),
                ],
                { params: { enterDuration: '0.4s' } }
            ),
        ]),
        trigger('enterLeaveFadeAnimation', [
            transition(':enter', [style({ opacity: 0 }), animate('{{ enterDuration }} 0s ease', style({ opacity: '*' }))], {
                params: { enterDuration: '0.4s' },
            }),
            transition(':leave', [style({ opacity: '*' }), animate('0.4s 0s ease', style({ opacity: 0 }))]),
        ]),
    ],
    templateUrl: './cpa-loading-inline.component.html',
    styleUrls: ['./cpa-loading-inline.component.scss'],
})
export class CpaLoadingInlineComponent {
    loading = true;

    @Input() enterDuration = '0.4s';
    @Input('loading') set setLoading(value: boolean) {
        if (this.loading !== value) {
            this.loading = value;
        }
    }
}
