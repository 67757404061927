import { Component, OnInit, Inject, Input } from '@angular/core';

import { BulletinsService } from './bulletins.service';
import { PlatformApiService } from '../api/platform-api.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { faCheck, faExclamationTriangle, faInfoCircle, faTimes, faBan } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'cpa-bulletins',
  templateUrl: './bulletins.component.html',
  styleUrls: ['./bulletins.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: '3rem', 'margin-bottom': '-3rem', opacity: 0 }),
            animate('.35s ease-in-out', style({ height: '*', 'margin-bottom': '*', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: '*', 'margin-bottom': '*', opacity: 1 }),
            animate('.35s ease-in-out', style({ height: '3rem', 'margin-bottom': '-3rem', opacity: 0 }))
          ]
        )
      ]
    )
  ],
})
export class BulletinsComponent implements OnInit {
  public faTimes = faTimes;
  public bulletins: any[];
  public applicationBulletins: any[];
  @Input() enablePlatformNotification = true;

  constructor(
    private readonly bulletinsService: BulletinsService,
    private readonly platformApiService: PlatformApiService) { }

  public ngOnInit(): void {
    // Fetch active bulletins from the platform
    this.platformApiService.getActiveBulletins().subscribe(
      (bulletins) => {
        // only application bulletins will be retrieved from core api
        this.bulletinsService.addApplicationBulletin.apply(this.bulletinsService, bulletins);
      },
      (err) => console.warn('Failed to fetch active bulletins.', err),
    );

    // Copy a reference to the bulletin list (since it's readonly in the service this is fine)
    this.bulletins = this.bulletinsService.bulletins;
    this.applicationBulletins = this.bulletinsService.applicationBulletins;
  }

  /**
   * Closes an active bulletin with an index
   * @param index the index of the bulletin to close
   */
  public close(index: number): void {
    this.bulletinsService.remove(index);
  }
  public closeApplicationBulletin(index: number): void {
    this.bulletinsService.removeApplicationBulletin(index);
  }

  /***
   * Returns a safe type, which is either a specific type or an empty string if not a default type.
   * @param type the bulletin type
   */
  public getSafeType(type: 'danger' | 'warning' | 'success' | 'info'): string {
    switch (type) {
      case 'danger':
      case 'success':
      case 'warning':
      case 'info':
        return type;
      default:
        return '';
    }
  }


  /**
   * Returns safely an icon to be used for this bulletin type.
   * @param type the bulletin type
   */
  public getIcon(type: 'danger' | 'warning' | 'success' | 'info'): IconDefinition {
    switch (type) {
      case 'danger':
        return faBan;
      case 'success':
        return faCheck;
      case 'warning':
        return faExclamationTriangle;
      case 'info':
      default:
        return faInfoCircle;
    }
  }
}
