import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpaScrollSpyDirective } from './scroll-spy.directive';
import { CpaHasScrollDirective } from './cpa-has-scroll-directive';
import { CpaDragAndDropDirective } from './drag-and-drop.directive';
import { CpaOnInitDirective } from './on-init.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    CpaScrollSpyDirective,
    CpaHasScrollDirective,
    CpaDragAndDropDirective,
    CpaOnInitDirective
  ],
  exports: [
    CpaScrollSpyDirective,
    CpaHasScrollDirective,
    CpaDragAndDropDirective,
    CpaOnInitDirective
  ]
})
export class DirectivesModule { }
