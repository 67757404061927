<div ngbDropdown display="dynamic" placement="bottom-right" class="position-inherit">
  <fa-layers id="notifications" class="pointer" title="Notifications" [fixedWidth]="true" ngbDropdownToggle>
    <fa-icon [icon]="faComment"></fa-icon>
    <fa-icon *ngIf="notifications.length" [icon]="faCircle" [styles]="{'color': '#C00000'}"
      transform="shrink-6 right-4 up-4"></fa-icon>
  </fa-layers>
  <div class="w-400" aria-labelledby="notifications" ngbDropdownMenu>
    <div class="dropdown-header border-bottom">
      <span class="font-weight-bold">Unread notifications ({{notifications.length}})</span>
      <a class="float-right pointer" title="Settings" (click)="loadSettings()" [href]="coreHome + '/#/settings/notifications/'">Settings</a>
    </div>
    <a class="dropdown-item border-bottom clickable" *ngFor="let notification of notifications"
       [href]="notification.targetURL ? notification.targetURL : coreHome + '/#/notifications' "
       [title]="htmlStripper.stripHtml(notification.content, false)">
      <span class="content" [innerHtml]="notification.content"></span>
      <div class="text-muted">
        <small [title]="getAppName(notification)">{{getAppName(notification)}}</small>
        <span class="float-right"><small>{{notification.occurredAt | date: 'medium'}}</small></span>
      </div>
    </a>
    <div class="dropdown-item border-bottom text-muted click-event" *ngIf="!notifications.length">You're up to date.</div>
    <a class="dropdown-item font-weight-bold clickable" [href]="coreHome + '/#/notifications'"
       title="View all notifications">View all notifications <fa-icon [icon]="faChevronRight"></fa-icon></a>
  </div>
</div>
