import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CpaFileComponent} from './cpa-file-drop.component';
import { CpaCloudIconComponent } from '../../icons/cpa-cloud-icon/cpa-cloud-icon.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    CpaFileComponent,
    CpaCloudIconComponent
  ],
  exports: [CpaFileComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
  ],
  providers: [],
  bootstrap: [CpaFileComponent],
})
export class CpaFileDropModule {}
