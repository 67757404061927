<div class="expandable">
  <div class="header d-flex" (click)="toggleExpanded()">
    <h4 class="cpa-ellipsize flex-grow-1">{{ label }} <span *ngIf="count || count === 0">({{count}})</span></h4>
    <div class="button">
      <fa-icon [icon]="faChevronDown" *ngIf="expanded" [fixedWidth]="true"></fa-icon>
      <fa-icon [icon]="faChevronRight" *ngIf="!expanded" [fixedWidth]="true"></fa-icon>
    </div>
  </div>
  <div class="content-container" [style.overflow]="overflow" [style.height]="height" #container>
    <div class="content" #content>
      <ng-content></ng-content>
    </div>
  </div>
</div>
