import { Component, Input, Output, EventEmitter } from '@angular/core';
import { faUser } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'cpa-profile',
  styleUrls: ['./profile.component.scss'],
  templateUrl: './profile.component.html',
})
export class ProfileComponent {
  public faUser = faUser;
  @Input() public readonly user: string;
  @Input() public readonly organisation: string;
  @Input() public readonly isAdmin: boolean;
  @Input() public readonly coreHome: string;
  @Output() public readonly logout: EventEmitter<void> = new EventEmitter<void>();

  public onLogout(): void {
    this.logout.emit(null);
  }
}
