<div class="form-group multiselect">
  <p-multiSelect [disabled]="disabled" [readonly]="readonly || disabled"
    [options]="options" [defaultLabel]="placeholder" (onBlur)="onTouched()"
    [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [filterPlaceHolder]="filterPlaceholder"
    [ngModel]="selectedOptionValues" (ngModelChange)="onChange($event)" [filter]="true" resetFilterOnHide="true" filterBy="label,value.name">
  </p-multiSelect>

  <cpa-helptext class="help-text" [ngClass]="{isDisabled: disabled}" *ngIf="helpText">{{helpText}}</cpa-helptext>
</div>

