import { Injectable } from '@angular/core';

interface IBulletin {
    type: 'danger' | 'warning' | 'success' | 'info';
    title: string;
    body: string;
    autoHide?: boolean;
    delay?: number;
}

@Injectable({ providedIn: 'root' })
export class BulletinsService {
    private readonly defaultDelay = 5000;

    /**
     * This property should not be used directly. If you want to add or remove bulletins, please use the methods `add` or `remove` instead.
     */
    public readonly bulletins: IBulletin[] = [];

    public readonly applicationBulletins: IBulletin[] = [];

    constructor() { }

    /**
     * Clonse the input and creates a completely new bulletin which will be available in the `cpa-bulletins`-component.
     * @param bulletin the new bulletin
     */
    public add(bulletin: IBulletin): void {
        if (bulletin !== undefined) {
            this.bulletins.push({ ...bulletin, delay: bulletin.delay || this.defaultDelay });
        }
    }

    /**
     * Removes a bulletin on the specified index. Does not validate the input.
     * @param index the index to remove
     */
    public remove(index: number): void {
        this.bulletins.splice(index, 1);
    }

    /**
     * the input and creates a completely new app bulletin which will be available in the `cpa-bulletins`-component.
     * @param bulletin the new bulletin
     */
    public addApplicationBulletin(bulletin: IBulletin): void {
        if (bulletin !== undefined) {
            this.applicationBulletins.push({ ...bulletin, delay: bulletin.delay || this.defaultDelay });
        }
    }

    /**
     * Removes an app bulletin on the specified index. Does not validate the input.
     * @param index the index to remove
     */
    public removeApplicationBulletin(index: number): void {
        this.applicationBulletins.splice(index, 1);
    }
}
