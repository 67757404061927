import {
    ElementRef,
    ComponentFactoryResolver,
    Renderer2,
    Directive,
    Input,
    ComponentRef,
    ApplicationRef,
    Injector,
    EmbeddedViewRef,
    TemplateRef,
    OnInit} from '@angular/core';
import { CpaOverlayComponent } from './cpa-overlay.component';
import { AfterViewInit, OnDestroy, NgZone } from '@angular/core';
import { DomHandler } from 'primeng/dom';
import { Position } from '../models/position.model';
import { StyleValues } from '../models/style-values.model';

@Directive({
    selector: '[cpaOverlay]'
})
export class CpaOverlayDirective implements AfterViewInit, OnDestroy  {
    private overlayComponentRef: ComponentRef<CpaOverlayComponent>;

    private arrowStyleValuesOptions = new Map<string, StyleValues>();

    sliceLength = 20;

    arrowRight = 0;

    arrowLeft = 0;

    arrowTop = 0;

    arrowBottom = 0;

    container: any;

    tooltipArrow: HTMLElement;

    tooltipContent: HTMLElement;

    active: boolean;

    mouseEnterListener: () => void;

    mouseLeaveListener: () => void;

    resizeListener: () => void;

    @Input() cpaOverlay: TemplateRef<any>;

    @Input() tooltipPosition = Position.RIGHT_CENTER;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private applicationRef: ApplicationRef,
        private injector: Injector,
        private componentFactoryResolver: ComponentFactoryResolver,
        public zone: NgZone) {}

    ngAfterViewInit() {
        this.zone.runOutsideAngular(() => {
            this.mouseEnterListener = this.onMouseEnter.bind(this);
            this.mouseLeaveListener = this.onMouseLeave.bind(this);

            this.el.nativeElement.addEventListener('mouseenter', this.mouseEnterListener);
            this.el.nativeElement.addEventListener('mouseleave', this.mouseLeaveListener);
        });
    }

    onMouseEnter(e: Event) {
        if (!this.container) {
            this.activate();
        }
    }

    onMouseLeave(e: Event) {
        this.deactivate();
    }

    activate() {
        this.active = true;

        this.show();
    }

    deactivate() {
        this.active = false;

        this.hide();
    }

    create() {
        if (this.container) {
            this.remove();
        }

        this.createOverlayElements();
        this.updateContent();

        this.renderer.appendChild(document.body, this.container);
    }

    show() {
        if (!this.cpaOverlay) {
            return;
        }

        this.create();
        this.populateArrowStyleValues();
        this.align();

        DomHandler.fadeIn(this.container, 250);
        this.bindDocumentResizeListener();
    }

    hide() {
        if (this.overlayComponentRef?.hostView) {
            this.applicationRef.detachView(this.overlayComponentRef.hostView);
            this.overlayComponentRef.destroy();
        }

        this.remove();
    }

    updateContent() {
        this.overlayComponentRef = this.componentFactoryResolver.resolveComponentFactory(CpaOverlayComponent).create(this.injector);
        this.overlayComponentRef.instance.contentTemplateRef = this.cpaOverlay;
        this.applicationRef.attachView(this.overlayComponentRef.hostView);
        const overlay = (this.overlayComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

        this.renderer.appendChild(this.tooltipContent, overlay);

        this.overlayComponentRef.hostView.detectChanges();
    }

    align() {
        const position = this.tooltipPosition;

        switch (position) {
            case Position.TOP_CENTER:
            case Position.TOP_LEFT:
            case Position.TOP_RIGHT:
                this.alignTop(position);
                break;

            case Position.BOTTOM_CENTER:
            case Position.BOTTOM_LEFT:
            case Position.BOTTOM_RIGHT:
                this.alignBottom(position);
                break;

            case Position.LEFT_CENTER:
            case Position.LEFT_TOP:
            case Position.LEFT_BOTTOM:
                this.alignLeft(position);
                break;

            case Position.RIGHT_CENTER:
            case Position.RIGHT_TOP:
            case Position.RIGHT_BOTTOM:
                this.alignRight(position);
                break;

            default:
                this.alignRight(Position.RIGHT_CENTER);
                break;
        }
    }

    getHostOffset() {
        const offset = this.el.nativeElement.getBoundingClientRect();
        const targetLeft = offset.left + DomHandler.getWindowScrollLeft();
        const targetTop = offset.top + DomHandler.getWindowScrollTop();

        return { left: targetLeft, top: targetTop };
    }

    alignRight(position: string) {
        this.preAlign('right');
        const hostOffset = this.getHostOffset();
        const left = hostOffset.left + DomHandler.getOuterWidth(this.el.nativeElement);
        const top = this.calculcateTopPosition(position, hostOffset.top);
        const elementStyle = this.arrowStyleValuesOptions.get(position);

        const styles: StyleValues[] = [
            new StyleValues(this.container, 'padding', '0 0.5rem'),
            new StyleValues(this.container, 'left', left / 16 + 'rem'),
            new StyleValues(this.container, 'top', top / 16 + 'rem'),
            new StyleValues(this.tooltipArrow, elementStyle.style, elementStyle.value),
            new StyleValues(this.tooltipArrow, 'marginTop', '-0.5rem'),
            new StyleValues(this.tooltipArrow, 'borderWidth', '0.5rem 0.5rem 0.5rem 0'),
            new StyleValues(this.tooltipArrow, 'borderRightColor', 'white')
        ];

        this.setStyles(styles);
    }

    alignLeft(position: string) {
        this.preAlign('left');
        const hostOffset = this.getHostOffset();
        const left = hostOffset.left - DomHandler.getOuterWidth(this.container);
        const top = this.calculcateTopPosition(position, hostOffset.top);
        const elementStyle = this.arrowStyleValuesOptions.get(position);

        const styles: StyleValues[] = [
            new StyleValues(this.container, 'padding', '0 0.5rem'),
            new StyleValues(this.container, 'marginLeft', '-0.5rem'),
            new StyleValues(this.container, 'left', left / 16 + 'rem'),
            new StyleValues(this.container, 'top', top / 16 + 'rem'),
            new StyleValues(this.tooltipArrow, elementStyle.style, elementStyle.value),
            new StyleValues(this.tooltipArrow, 'marginTop', '-0.5rem'),
            new StyleValues(this.tooltipArrow, 'borderWidth', '0.5rem 0 0.5rem 0.5rem'),
            new StyleValues(this.tooltipArrow, 'borderLeftColor', 'white')
        ];

        this.setStyles(styles);
    }

    alignTop(position: string) {
        this.preAlign('top');
        const hostOffset = this.getHostOffset();
        const left = this.calculateLeftPosition(position, hostOffset.left);
        const top = hostOffset.top - DomHandler.getOuterHeight(this.container);
        const elementStyle = this.arrowStyleValuesOptions.get(position);

        const styles: StyleValues[] = [
            new StyleValues(this.container, 'padding', '0.5rem 0'),
            new StyleValues(this.container, 'marginTop', '-0.4375rem'),
            new StyleValues(this.container, 'left', left / 16 + 'rem'),
            new StyleValues(this.container, 'top', top / 16 + 'rem'),
            new StyleValues(this.tooltipArrow, elementStyle.style, elementStyle.value),
            new StyleValues(this.tooltipArrow, 'marginLeft', '-0.5rem'),
            new StyleValues(this.tooltipArrow, 'borderWidth', '0.5rem 0.5rem 0'),
            new StyleValues(this.tooltipArrow, 'borderTopColor', 'white')
        ];

        this.setStyles(styles);
    }

    alignBottom(position: string) {
        this.preAlign('bottom');
        const hostOffset = this.getHostOffset();
        const left = this.calculateLeftPosition(position, hostOffset.left);
        const top = hostOffset.top + DomHandler.getOuterHeight(this.el.nativeElement);
        const elementStyle = this.arrowStyleValuesOptions.get(position);

        const styles: StyleValues[] = [
            new StyleValues(this.container, 'padding', '0.5rem 0'),
            new StyleValues(this.container, 'left', left / 16 + 'rem'),
            new StyleValues(this.container, 'top', top / 16 + 'rem'),
            new StyleValues(this.tooltipArrow, elementStyle.style, elementStyle.value),
            new StyleValues(this.tooltipArrow, 'marginLeft', '-0.5rem'),
            new StyleValues(this.tooltipArrow, 'borderWidth', '0 0.5rem 0.5rem'),
            new StyleValues(this.tooltipArrow, 'borderBottomColor', 'white')
        ];

        this.setStyles(styles);
    }

    preAlign(position: string) {
        this.container.style.left = -999 / 16 + 'rem';
        this.container.style.top = -999 / 16 + 'rem';

        const defaultClassName = 'ui-tooltip ui-widget ui-tooltip-' + position;
        this.container.className = defaultClassName;
    }

    onWindowResize(e: Event) {
        this.hide();
    }

    bindDocumentResizeListener() {
        this.zone.runOutsideAngular(() => {
            this.resizeListener = this.onWindowResize.bind(this);
            window.addEventListener('resize', this.resizeListener);
        });
    }

    unbindDocumentResizeListener() {
        if (this.resizeListener) {
            window.removeEventListener('resize', this.resizeListener);
            this.resizeListener = null;
        }
    }

    unbindEvents() {
        this.el.nativeElement.removeEventListener('mouseenter', this.mouseEnterListener);
        this.el.nativeElement.removeEventListener('mouseleave', this.mouseLeaveListener);

        this.unbindDocumentResizeListener();
    }

    remove() {
        if (this.container && this.container.parentElement) {
            document.body.removeChild(this.container);
        }

        this.unbindDocumentResizeListener();
        this.container = null;
    }

    ngOnDestroy() {
        this.unbindEvents();
        this.remove();
    }

    private calculcateTopPosition(position: string, hostOffsetTop: number) {
        let top = 0;

        switch (position) {
            case Position.RIGHT_CENTER:
            case Position.LEFT_CENTER:
                top = hostOffsetTop
                        + (DomHandler.getOuterHeight(this.el.nativeElement)
                        - DomHandler.getOuterHeight(this.container)) / 2;
                break;

            case Position.RIGHT_TOP:
            case Position.LEFT_TOP:
                top = hostOffsetTop
                        + ((DomHandler.getOuterHeight(this.el.nativeElement) / 2)
                        - DomHandler.getOuterHeight(this.container))
                        + this.sliceLength;
                break;

            case Position.RIGHT_BOTTOM:
            case Position.LEFT_BOTTOM:
                top = hostOffsetTop
                        + (DomHandler.getOuterHeight(this.el.nativeElement) / 2)
                        - this.sliceLength;
                break;

            default:
                top = hostOffsetTop
                        + (DomHandler.getOuterHeight(this.el.nativeElement)
                        - DomHandler.getOuterHeight(this.container)) / 2;
                break;
        }

        return top;
    }

    private calculateLeftPosition(position: string, hostOffsetLeft: number) {
        let left = 0;

        switch (position) {
            case Position.BOTTOM_CENTER:
            case Position.TOP_CENTER:
                left = hostOffsetLeft
                        + ((DomHandler.getOuterWidth(this.el.nativeElement))
                        - DomHandler.getOuterWidth(this.container)) / 2;
                break;

            case Position.BOTTOM_RIGHT:
            case Position.TOP_RIGHT:
                left = (hostOffsetLeft
                        + (DomHandler.getOuterWidth(this.el.nativeElement) / 2))
                        - this.sliceLength;
                break;

            case Position.BOTTOM_LEFT:
            case Position.TOP_LEFT:
                left = (hostOffsetLeft
                        + ((DomHandler.getOuterWidth(this.el.nativeElement) / 2)
                        - DomHandler.getOuterWidth(this.container)))
                        + this.sliceLength;
                break;

            default:
                left = hostOffsetLeft
                        + ((DomHandler.getOuterWidth(this.el.nativeElement))
                        - DomHandler.getOuterWidth(this.container)) / 2;
                break;
        }

        return left;
    }

    private createOverlayElements() {
        this.container = this.renderer.createElement('div');
        this.setContainerStyle();

        this.tooltipArrow = this.renderer.createElement('div');
        this.tooltipArrow.className = 'ui-tooltip-arrow';

        this.tooltipContent = this.renderer.createElement('div');
        this.setTooltipContentStyle();

        this.renderer.appendChild(this.container, this.tooltipArrow);
        this.renderer.appendChild(this.container, this.tooltipContent);
    }

    private setTooltipContentStyle() {
        const styles: StyleValues[] = [
            new StyleValues(this.tooltipContent, 'backgroundColor', 'white'),
            new StyleValues(this.tooltipContent, 'boxShadow', '0 0.125rem 0.875rem 0 rgba(0, 0, 0, 0.22)')
        ];

        this.setStyles(styles);
    }

    private setContainerStyle() {
        const styles: StyleValues[] = [
            new StyleValues(this.container, 'zIndex', ++DomHandler.zindex),
            new StyleValues(this.container, 'display', 'inline-block'),
            new StyleValues(this.container, 'margin', 'auto'),
            new StyleValues(this.container, 'maxWidth', '100%')
        ];

        this.setStyles(styles);
    }

    private setStyles(styles: StyleValues[]) {
        styles.forEach(style => this.renderer.setStyle(style.element, style.style, style.value));
    }

    private populateArrowStyleValues() {
        this.calculateArrowPositions();

        this.arrowStyleValuesOptions.set(Position.TOP_CENTER, new StyleValues(this.tooltipArrow, 'left', '50%'));
        this.arrowStyleValuesOptions.set(Position.TOP_RIGHT, new StyleValues(this.tooltipArrow, 'left', this.arrowLeft + '%'));
        this.arrowStyleValuesOptions.set(Position.TOP_LEFT, new StyleValues(this.tooltipArrow, 'left', this.arrowRight + '%'));

        this.arrowStyleValuesOptions.set(Position.BOTTOM_CENTER, new StyleValues(this.tooltipArrow, 'left', '50%'));
        this.arrowStyleValuesOptions.set(Position.BOTTOM_RIGHT, new StyleValues(this.tooltipArrow, 'left', this.arrowLeft + '%'));
        this.arrowStyleValuesOptions.set(Position.BOTTOM_LEFT, new StyleValues(this.tooltipArrow, 'left', this.arrowRight + '%'));

        this.arrowStyleValuesOptions.set(Position.RIGHT_CENTER, new StyleValues(this.tooltipArrow, 'top', '50%'));
        this.arrowStyleValuesOptions.set(Position.RIGHT_TOP, new StyleValues(this.tooltipArrow, 'top', this.arrowTop + '%'));
        this.arrowStyleValuesOptions.set(Position.RIGHT_BOTTOM, new StyleValues(this.tooltipArrow, 'top', this.arrowBottom + '%'));

        this.arrowStyleValuesOptions.set(Position.LEFT_CENTER, new StyleValues(this.tooltipArrow, 'top', '50%'));
        this.arrowStyleValuesOptions.set(Position.LEFT_TOP, new StyleValues(this.tooltipArrow, 'top', this.arrowTop + '%'));
        this.arrowStyleValuesOptions.set(Position.LEFT_BOTTOM, new StyleValues(this.tooltipArrow, 'top', this.arrowBottom + '%'));
    }

    calculateArrowPositions() {
        this.arrowTop = ((DomHandler.getOuterHeight(this.container) - this.sliceLength)
                        / DomHandler.getOuterHeight(this.container))
                        * 100;

        this.arrowBottom = 100 - this.arrowTop;

        this.arrowRight = ((DomHandler.getOuterWidth(this.container) - this.sliceLength)
                          / DomHandler.getOuterWidth(this.container))
                          * 100;

        this.arrowLeft = 100 - this.arrowRight;
    }
}
