import { Component, Input, Output, TemplateRef, EventEmitter, OnInit } from '@angular/core';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons';
import { faSquareFull } from '@fortawesome/pro-duotone-svg-icons';

@Component({
  selector: 'cpa-select-item',
  templateUrl: './cpa-select-item.component.html'
})
export class CpaSelectItemComponent implements OnInit {
  public faSquare = faSquare;
  public faCheckSquare = faCheckSquare;
  public faSquareFull = faSquareFull;
  optional = false;

  @Input() hovered = false;

  @Input() option: any;

  @Input() selected: boolean;

  @Input() hoveredSelected: boolean;

  @Input() disabled: boolean;

  @Input() visible: boolean;

  @Input() itemSize: number;

  @Input() iconType: 'fas' | 'far' | 'fal' | 'fad' | 'fab' = 'far';

  @Input() icon: string | undefined;

  @Input() template: TemplateRef<any>;

  @Input() multiSelect = false;

  @Output() dropdownItemClick: EventEmitter<any> = new EventEmitter();

  @Output() dropdownItemHovered: EventEmitter<any> = new EventEmitter();

  @Output() dropdownItemRemoved: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    if (this.option.optional) {
      this.optional = true;
    }
  }

  onOptionClick(event: Event) {
    if (!this.option.disabled) {
      this.dropdownItemClick.emit({
        originalEvent: event,
        option: this.option
      });
    } else {
      event.stopPropagation();
    }
  }

  onMouseEnter(event) {
    if (!this.option.disabled) {
      this.dropdownItemHovered.emit({
        hovered: true,
        option: this.option
      });
      this.hovered = true;
    }
  }

  onMouseLeave(event) {
    this.hovered = false;
  }

  onRemoveItem(event) {
      event.stopPropagation();

      if (!this.option.disabled) {
      this.dropdownItemRemoved.emit({
        option: this.option
      });
    }
  }
}
