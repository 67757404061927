import { Component, OnInit, HostBinding, Input, Output, EventEmitter, Optional, Self, ViewChild, Renderer2 } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl, ValidationErrors } from '@angular/forms';
import { NgbInputDatepicker, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'cpa-date-picker-with-reactive-forms-support',
  templateUrl: './cpa-date-picker-with-reactive-forms-support.component.html',
  styleUrls: ['./cpa-date-picker-with-reactive-forms-support.component.scss']
})
export class CpaDatePickerWithReactiveFormsSupportComponent implements OnInit, ControlValueAccessor {
  public faCalendar = faCalendar;

  @Input() label: string;
  @Input() placeholder = '';
  @Input() helpText = '';
  @Input() markDisabled = false;
  @Input() autocomplete = 'off';
  @Input() minDate: NgbDateStruct;
  @Input() maxDate: NgbDateStruct;

  @Input() autoClose = 'outside';
  @ViewChild('d', { static: true }) private datePicker: NgbInputDatepicker;

  @Input() value: NgbDate;

  @HostBinding('class.read-only')
  @Input() readonly: boolean;

  @Output() dateSelect = new EventEmitter<any>();
  @Output() resetDate = new EventEmitter<any>();
  @Input() container: string;

  @Input() disabled: boolean;
  @HostBinding('class.disabled')
  get calculatedDisabledState() {
    return (!!this.disabled || (!!this.readonly && !this.value));
  }

  @Input() datePickerAdditionalClass = '';

  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl) {
      if (this.ngControl) {
        this.ngControl.valueAccessor = this;
      }
    }

  ngOnInit(): void {
    if (this.ngControl) {
      const predefinedValidators = [this.validate.bind(this)];
      const validators = this.ngControl.control.validator ?
                          [this.ngControl.control.validator, ...predefinedValidators]
                          :
                          predefinedValidators;
      this.ngControl.control.setValidators(validators);
      this.ngControl.control.updateValueAndValidity();
    }
  }

  validate({ value }: UntypedFormControl): ValidationErrors | null {
    if (value) {
      if (typeof this.value === 'string') {
        const regex = new RegExp('^((0[1-9]|[1-2]d|3[01])-(0[1-9]|1[0-2])-([12]d{3}))$');
        const isValid = regex.test(value.toString());

        if (!isValid) {
          return { format: true };
        }
      } else {
          const date = new Date(this.value.year, this.value.month, this.value.day);
          const minDate = this.getConvertedDate(this.minDate);
          const maxDate = this.getConvertedDate(this.maxDate);

          if (minDate && (date < minDate)) {
            return { minDate: true };
          }

          if (maxDate && (date > maxDate)) {
            return { maxDate: true };
          }
      }
    }

    return null;
  }

  onChange: (_: any) => void = (_: any) => {};
  onTouched: () => void = () => {};

  writeValue(value: NgbDate): void {
    this.value = value;
    this.onChange(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  openDatepicker(datepicker: NgbInputDatepicker) {
    if (!this.readonly) {
      datepicker.open();

      if (this.datePickerAdditionalClass)
      {
        // Hack: we need to add class to datepicker in order to use it in custom agGrid filter. This is working workaround.
        const renderer = ((datepicker as any)._renderer as Renderer2);
        const nativeElement = (datepicker as any)._cRef?.location?.nativeElement;
        if (!!renderer && !!nativeElement) {
          renderer.addClass(nativeElement, this.datePickerAdditionalClass);
        }
      }
    }
  }

  hasFormValue() {
    return !!this.value;
  }

  onSelectDate(datePicker: NgbInputDatepicker) {
    this.dateSelect.emit(this.value);
    datePicker.close();
  }

  onReset() {
    this.value = null;
    this.onChange(this.value);

    this.resetDate.emit(this.value);
  }

  onKeydown(event) {
    if (event.key === 'ArrowDown') {
      if (!this.readonly) {
        this.datePicker.open();
      }
      event.preventDefault();
    }
  }

  private getConvertedDate(date: NgbDateStruct): Date {
    let convertedDate: Date;
    if (date) {
      convertedDate = new Date(date.year, date.month, date.day);
    }

    return convertedDate ?? null;
  }
}
