<p-table #tbl [value]="options.items" class="cpa-grid" [lazy]="options.enableLazyLoading"
  (onLazyLoad)="lazyLoad($event)" (onPage)="onPaginate($event)" sortMode="multiple" [multiSortMeta]="options.sorting" styleClass="ui-fluid"
  [lazyLoadOnInit]="options.lazyLoadOnInit" [paginator]="options.pagination" [first]="first" [rows]="options.pagination?.rowsPerPage"
  [rowsPerPageOptions]="getRowsPerPageOptions()" [resetPageOnSort]="options.pagination?.resetPageOnSort"
  [globalFilterFields]="globalFilterFields"
  [totalRecords]="getTotalRecords()" [dataKey]="options.dataKey" [(selection)]="options.selection">
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
        <col *ngFor="let col of options.columns" [style.width]="col.width">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" class="cpa-grid">
    <tr [ngClass]="{'header-with-filter': hasFilters===true}">
      <th class="e2e-grid-column" *ngFor="let column of options.columns" [pSortableColumn]="column.field"
        [pSortableColumnDisabled]="column.disableSorting || column.type === 'checkbox'"
        [ngClass]="{'cell-with-checkbox': column.type === 'checkbox'}"
        [ngSwitch]="column.type">
        <p-tableHeaderCheckbox *ngSwitchCase="'checkbox'"></p-tableHeaderCheckbox>
        <ng-container *ngSwitchDefault>{{column.header}}</ng-container>
        <p-sortIcon [field]="column.field" *ngIf="!column.disableSorting && column.type !== 'checkbox'"></p-sortIcon>
      </th>
    </tr>
    <tr *ngIf="hasFilters">
      <th class="column-filter" *ngFor="let column of options.columns" [ngSwitch]="column.filter?.type">
        <p-tableHeaderCheckbox *ngSwitchCase="'checkbox'"></p-tableHeaderCheckbox>
        <cpa-text-input *ngSwitchCase="'text'" (input)="tbl.filter($event.target.value, column.field, 'contains')">
        </cpa-text-input>
        <cpa-multiselect-dropdown-list *ngSwitchCase="'multiselect'" [placeholder]="''"
          [options]="column.filter.options" [ngModel]="tbl.filters[column.header]" (ngModelChange)="tbl.filter($event, column.field, 'in')">
        </cpa-multiselect-dropdown-list>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-item>
    <tr class="e2e-grid-row" [ngClass]="{ 'clickable': isRowClickable() }" (click)="rowClick.emit({event:$event, data:item})" >
      <td *ngFor="let column of options.columns" [ngSwitch]="column.type">
        <p-tableCheckbox *ngSwitchCase="'checkbox'" [value]="item"></p-tableCheckbox>
        <span *ngSwitchCase="'text'">{{item[column.field]}}</span>
        <div *ngSwitchCase="'template'">
          <ng-container *ngTemplateOutlet="templates[column.field];context:{item: item, filter: filter}"></ng-container>
        </div>
        <span *ngSwitchDefault>Could not render data for column of type: {{column.type}}</span>
      </td>
    </tr>
  </ng-template>
  <ng-template *ngIf="captionTemplate" pTemplate="caption">
    <ng-container *ngTemplateOutlet="captionTemplate;context:{rows: tbl.rows, totalRecords: tbl.totalRecords}"></ng-container>
  </ng-template>
  <ng-template *ngIf="paginatorLeftTemplate" pTemplate="paginatorleft">
    <ng-container *ngTemplateOutlet="paginatorLeftTemplate"></ng-container>
  </ng-template>
  <ng-template *ngIf="paginatorRightTemplate" pTemplate="paginatorright">
    <ng-container *ngTemplateOutlet="paginatorRightTemplate"></ng-container>
  </ng-template>
</p-table>
