import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { CpaFileUploaderComponent } from './cpa-file-uploader.component';
import { CpaFileDropZoneModule } from '../cpa-file-drop-zone/cpa-file-drop-zone.module';
import { CpaFileListModule } from '../cpa-file-list/cpa-file-list.module';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    CpaFileDropZoneModule,
    CpaFileListModule
  ],
  declarations: [
    CpaFileUploaderComponent
  ],
  exports: [
    CpaFileUploaderComponent
  ]
})
export class CpaFileUploaderModule {}
