export interface CpaFileMimeTypeResolver {
  resolve(file: File): string;
}

export class CpaDefaultMimeTypeResolver implements CpaFileMimeTypeResolver {
  resolve(file: File): string {
    // The type from the file can be spoofed, so
    // this is where you would put the code to check
    // the file content's magic numbers and see if
    // the mime types match up.
    return file.type;
  }
}
