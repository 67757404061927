import { Component, OnInit, Input, HostBinding, Output, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { faTrashAlt, faTimes, faDownload } from '@fortawesome/pro-regular-svg-icons';
import { CpaFile, CpaFileConfig, CpaFileTemplates } from './cpa-file.models';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'cpa-file',
  templateUrl: './cpa-file.component.html',
  styleUrls: ['./cpa-file.component.scss'],
  animations: [
    trigger(
      'fileAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('.35s ease-in-out', style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('.35s ease-in-out', style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class CpaFileComponent implements OnInit {
  faTrashAlt = faTrashAlt;
  faTimes = faTimes;
  faDownload = faDownload;

  @Input() file: CpaFile;
  @Input() config: CpaFileConfig;
  @Input() templates: CpaFileTemplates;

  @HostBinding('class.preview') get preview() { return this.config?.previews; }
  @HostBinding('class.danger') get error() { return !!this.file?.error; }
  @HostBinding('class.processing') get processing() { return this.file?.progress != null; }
  @HostBinding('class.success') get success() { return !!this.file?.rawFile && this.file?.progress == null && !this.file?.error; }

  @Output() remove = new EventEmitter<void>();
  @Output() fileClick = new EventEmitter<void>();

  @ViewChild('imageCanvas')
  imageCanvas: HTMLCanvasElement;

  constructor() {
  }

  ngOnInit() {
  }
}
