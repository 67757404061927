import { Component, Input, OnInit, Self, Optional, Output, EventEmitter, HostBinding } from '@angular/core';
import { ControlValueAccessor, NgControl, AbstractControl } from '@angular/forms';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'cpa-text-input',
  templateUrl: './cpa-text-input.component.html',
  styleUrls: ['./cpa-text-input.component.scss'],
})
export class CpaTextInputComponent implements OnInit, ControlValueAccessor {


  @HostBinding('class.read-only')
  @Input() readonly: boolean;

  @HostBinding('class.required')
  get required(): boolean {
    if (this.ngControl && this.ngControl.control && this.ngControl.control.validator) {
      const validators = this.ngControl.control.validator({} as AbstractControl);
      return (validators || {}).required;
    }
  }

  @HostBinding('class.has-icon')
  get hasIcon(): boolean {
    return !!this.icon;
  }

  @Input() label: string;
  @Input() placeholder = '';
  @Input() helpText = '';
  @Input() type: 'text' | 'email' | 'password' | 'number' = 'text';
  @Input() autocomplete = 'on';
  @Input() iconType: 'fas' | 'far' | 'fal' | 'fad' | 'fab' = 'fas';
  @Input() icon: string | IconDefinition | undefined;
  @Input() value: any = '';
  @Input() showClear: boolean;

  clearIcon = faTimes;

  @Input() disabled: boolean;
  @HostBinding('class.disabled')
  get calculatedDisabledState() {
    return (!!this.disabled || (!!this.readonly && !this.value));
  }

  @Output() enterKeyPressed: EventEmitter<void> = new EventEmitter();

  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {}

  onEnterKeyPressed() {
    this.enterKeyPressed.emit();
  }

  onChange: (_: any) => void = (_: any) => {};
  onTouched: () => void = () => {};

  writeValue(value: any): void {
    this.value = value;
    this.onChange(this.value);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  clearValue(): void {
    this.value = '';
    this.onChange(this.value);
  }

  get isIconTypeString(): boolean {
    return this.icon && !this.isIconTypeIconDefinition;
  }

  get isIconTypeIconDefinition(): boolean {
    const iconAny = this.icon as any;
    return (iconAny && iconAny.iconName);
  }
}
