import { Component, HostBinding, Input, Self, Optional, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, AbstractControl, NgControl } from '@angular/forms';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'cpa-radio-button',
  templateUrl: './cpa-radio-button.component.html',
  styleUrls: ['./cpa-radio-button.component.scss'],
})
export class CpaRadioButtonComponent implements ControlValueAccessor {
  @Input() options: Map<string, any> = new Map<string, any>();
  @Input() label: string;
  @Input() helpText: string;
  @Input() sortFn: (a: KeyValue<string, any>, b: KeyValue<string, any>) => number;
  @Output() selectedOption: EventEmitter<any> = new EventEmitter();

  @HostBinding('class.disabled')
  @Input() disabled: boolean;

  @HostBinding('class.required')
  get required(): boolean {
    if (this.ngControl && this.ngControl.control && this.ngControl.control.validator) {
      const validators = this.ngControl.control.validator({} as AbstractControl);
      return (validators || {}).required;
    }
  }

  private innerValue: any;
  get value(): any {
    return this.innerValue;
  }

  @Input()
  set value(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  onChange: (_: any) => void = (_: any) => {};
  onTouched: () => void = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  onModelChange(value: any): void {
    this.value = value;
    this.onChange(value);
    this.selectedOption.emit(value);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
