<div class="cpa-mr-1"
     (mouseover)="preview && ((this.file?.downloadLink && overlayPanel?.open($event, leadingContent)) || (!this.file?.downloadLink && this.file?.src && tooltip)?.open($event, leadingContent))"
     (mouseout)="tooltip.close(); overlayPanel.close()"
     #leadingContent>
  <ng-container *ngIf="!file?.error && file?.progress == null">
    <ng-container *ngIf="preview; else iconTemplate">
      <div class="preview-box">
        <ng-container *ngTemplateOutlet="templates?.thumbnailTemplate || defaultThumbnailTemplate;context:{ file: this.file }"></ng-container>
      </div>
    </ng-container>
    <ng-template #iconTemplate>
      <div class="icon">
        <fa-icon class="leading-icon" [icon]="file?.name | faFileIcon"></fa-icon>
      </div>
    </ng-template>
  </ng-container>
  <cpa-overlay-panel placement="top" #overlayPanel>
    <ng-container *ngTemplateOutlet="templates?.overlayPanelTemplate || defaultOverlayPanelTemplate;context:{ file: this.file }"></ng-container>
  </cpa-overlay-panel>
</div>
<cpa-tooltip class="cpa-slim-padding" placement="top" #tooltip>
  <ng-container *ngTemplateOutlet="templates?.tooltipTemplate || defaultTooltipTemplate;context:{ file: this.file }"></ng-container>
</cpa-tooltip>
<div class="description" (click)="fileClick.next()">
  <span class="file-name">{{ file?.name }}</span>
  <div class="position-relative">
    <ng-container *ngTemplateOutlet="file?.progress != null ? processingTemplate : templates?.detailsTemplate || defaultDetailsTemplate;context:{ file: this.file }">
    </ng-container>
  </div>
</div>
<div *ngIf="!processing" [hidden]="remove.observers.length === 0" class="icon trailing-icon-container cpa-ml-1">
  <fa-icon class="trailing-icon" [icon]="file?.error ? faTimes : faTrashAlt" (click)="remove.emit()"></fa-icon>
</div>

<ng-template #defaultThumbnailTemplate let-file="file">
  <cpa-file-img class="image" [src]="file?.thumbnailSrc || file?.src" [fallbackTemplate]="iconTemplate" thumbnail cover></cpa-file-img>
  <ng-template #iconTemplate>
    <div class="icon-container">
      <div class="icon">
        <fa-icon class="leading-icon" [icon]="file?.name | faFileIcon:true"></fa-icon>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #defaultOverlayPanelTemplate let-file="file">
  <div class="overlay-panel">
    <div class="full-image-container">
      <cpa-file-img class="full-image" [src]="file?.src" [fallbackTemplate]="errorTemplate" contain></cpa-file-img>
    </div>
    <button *ngIf="file?.downloadLink" class="cpa-mt-1 w-100 cpa-btn btn btn-outline-secondary" (click)="downloadLink.click()">
      <a [hidden]="true" [href]="file?.downloadLink" download #downloadLink></a>
      <span>Download {{ file?.type?.startsWith('image') ? 'image' : 'file' }}</span><fa-icon [icon]="faDownload"></fa-icon>
    </button>
  </div>
</ng-template>

<ng-template #defaultTooltipTemplate let-file="file">
  <div class="full-image-container">
    <cpa-file-img class="full-image" [src]="file?.src" [fallbackTemplate]="errorTemplate" contain></cpa-file-img>
  </div>
</ng-template>

<ng-template #processingTemplate>
  <div class="position-absolute" [ngStyle]="{ top: 0, right: 0, left: 0 }" [@fileAnimation]>
    <cpa-file-progress-bar [progress]="file?.progress" (cancel)="remove.emit()"></cpa-file-progress-bar>
  </div>
</ng-template>

<ng-template #defaultDetailsTemplate let-file="file">
  <div [@fileAnimation]>
    <span class="file-details">{{ file?.error || ([(file?.size | readableBytes), file?.details] | arrayJoin:' | ') }}</span>
  </div>
</ng-template>

<ng-template #errorTemplate>
  <div class="preview-unavailable">
    <span>Preview not available</span>
  </div>
</ng-template>
