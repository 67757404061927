import { CpaSideMenuHeaderComponent } from './cpa-side-menu-header/side-menu-header.component';
import { CpaSideMenuItemComponent } from './cpa-side-menu-item/side-menu-item.component';
import { Component, Output, EventEmitter, QueryList, AfterContentInit, ContentChildren, Optional,
  Input, HostBinding, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { scalePxToRemPx } from '../utils/px.utils';

@Component({
  selector: 'cpa-side-menu',
  styleUrls: ['./side-menu.component.scss'],
  templateUrl: './side-menu.component.html',
})
export class CpaSideMenuComponent implements AfterContentInit, OnDestroy {
  private readonly sideMenuWidthPx = 232;
  private readonly sideMenuWidthCollapsedPx = 64;
  private readonly baseFontSize = 16;

  @HostBinding('style.width.px')
  width: number;

  @HostBinding('class.collapsed')
  isCollapsed: boolean;

  @Input() set collapsed(value: boolean) {
    this.isCollapsed = value;
    this.collapsedChanged.emit(this.isCollapsed);
    this.refresh();
  }

  selectedHrefValue: string;

  @Input() set selectedHref(value: string) {
    this.selectedHrefValue = value;
    this.updateSelection(this.selectedHrefValue);
  }

  @Input() useRouter = true;
  @Output() collapsedChanged = new EventEmitter<boolean>();
  @Output() itemClicked = new EventEmitter<CpaSideMenuItemComponent>();

  routerSubscription: Subscription;
  sideMenuItemSubscriptions: Subscription[] = [];
  contentInitialized: boolean;

  @ContentChildren(CpaSideMenuHeaderComponent) sideMenuHeaders !: QueryList<CpaSideMenuHeaderComponent>;
  @ContentChildren(CpaSideMenuItemComponent, { descendants: true }) sideMenuItems !: QueryList<CpaSideMenuItemComponent>;

  constructor(@Optional() private router: Router) {
  }

  ngAfterContentInit(): void {
    this.contentInitialized = true;
    if (this.router) {
      this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(() => this.refreshSelectionFromRoute());
    }
    this.updateItemSubscriptions();
    this.sideMenuItems.changes.subscribe(() => {
      this.updateItemSubscriptions();
    });
    this.refresh();
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    this.unsubscribeItemSubscriptions();
  }

  private updateSelection(href: string) {
    if (this.contentInitialized) {
      let selectedItem: CpaSideMenuItemComponent = null;
      this.sideMenuItems.forEach(item => {
        item.open = false;
        item.selected = false;
        if (href.startsWith(item.href)) {
          selectedItem = item;
        }
      });
      if (selectedItem != null) {
        if (!this.isCollapsed) {
          if (selectedItem.parent != null) {
            selectedItem.parent.open = true;
          }
          if (selectedItem.children.length > 0) {
            selectedItem.open = true;
          }
        } else if (selectedItem.parent != null) {
          selectedItem.parent.selected = true;
        }
        selectedItem.selected = true;
      }
    }
  }

  private updateItemSubscriptions() {
    this.unsubscribeItemSubscriptions();
    this.sideMenuItems.forEach(item => {
      this.sideMenuItemSubscriptions.push(item.clicked.subscribe(clickedItem => this.onItemClick(clickedItem)));
    });
  }

  private unsubscribeItemSubscriptions() {
    this.sideMenuItemSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.sideMenuItemSubscriptions.length = 0;
  }

  private refresh() {
    if (this.contentInitialized) {
      this.sideMenuItems.forEach(item => {
        item.open = false;
        item.collapsed = this.isCollapsed;
      });
      this.sideMenuHeaders.forEach(item => {
        item.collapsed = this.isCollapsed;
      });
      this.refreshSelectionFromRoute();
      this.width = this.getSideMenuWidth();
    }
  }

  private refreshSelectionFromRoute() {
    if (this.router && this.useRouter) {
      this.selectedHref = this.router.url;
    }
  }

  private onItemClick(item: CpaSideMenuItemComponent) {
    if (item.href && this.router && this.useRouter) {
      this.router.navigateByUrl(item.href);
    }
    this.itemClicked.emit(item);
  }

  toggleCollapsed() {
    this.collapsed = !this.isCollapsed;
  }

  private getSideMenuWidth() {
    const widthPx = this.isCollapsed ? this.sideMenuWidthCollapsedPx : this.sideMenuWidthPx;
    return scalePxToRemPx(widthPx, this.baseFontSize) + this.getScrollbarWidth();
  }

  private getScrollbarWidth(): number {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    document.body.appendChild(outer);

    const inner = document.createElement('div');
    outer.appendChild(inner);

    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  }
}
