import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ExpansionPanelToggleEvent } from './cpa-expansion-panel.models';
import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'cpa-expansion-panel,cpa-expandable',
  templateUrl: './cpa-expansion-panel.component.html',
  styleUrls: ['./cpa-expansion-panel.component.scss'],
})
export class CpaExpansionPanelComponent implements OnInit, OnDestroy, AfterViewInit {
  public faChevronDown = faChevronDown;
  public faChevronRight = faChevronRight;
  private readonly borderWidth = 1;
  private readonly transitionEndEvents = ['transitionend', 'webkitTransitionEnd', 'otransitionend', 'oTransitionEnd', 'msTransitionEnd'];

  @Input() label = '';
  @Input() count: number;
  @Output() toggle = new EventEmitter<ExpansionPanelToggleEvent>();

  @ViewChild('container') containerElement;
  @ViewChild('content') contentElement;

  expanded = true;
  @Input('expanded') set setExpanded(value: boolean) {
    this.expanded = value;
    this.toggle.emit({
      label: this.label,
      expanded: this.expanded,
    });
    this.refreshOpenHeight();
    this.onExpandedChange();
  }

  private viewInitialized: boolean;
  private openHeight: string;
  height = 'auto';
  overflow: string;

  constructor(private el: ElementRef, private changeDetector: ChangeDetectorRef) {
    const tagName = el.nativeElement.tagName.toLowerCase();
    if (tagName === 'cpa-expandable') {
       console.warn(`'cpa-expandable' has been renamed to 'cpa-expansion-panel'`);
    }
  }

  toggleExpanded() {
    this.setExpanded = !this.expanded;
  }

  ngOnInit(): void {
    if (this.expanded) {
      this.refresh('visible', this.height);
    } else {
      this.refresh('hidden', this.height);
    }
  }

  onExpandedChange() {
    if (this.height === 'auto') {
      this.refresh(this.overflow, this.openHeight);
      setTimeout(() => {
        this.onExpandedChange();
      }, 0);
    } else {
      this.refreshHeight();
    }
  }

  ngOnDestroy(): void {
    this.removeTransitionListeners();
  }

  ngAfterViewInit(): void {
    this.addTransitionListeners();
    this.viewInitialized = true;
    this.refreshHeight();
  }

  refreshOpenHeight() {
    if (this.viewInitialized) {
      this.openHeight = this.contentElement.nativeElement.offsetHeight + this.borderWidth * 2 + 'px';
    }
  }

  refreshHeight() {
    if (this.expanded) {
      if (this.openHeight != null) {
        setTimeout(() => {
          this.refresh('hidden', this.openHeight);
        }, 0);
      } else {
        this.refresh(this.overflow, 'auto');
      }
    } else {
      setTimeout(() => {
        this.refresh('hidden', this.borderWidth * 2 + 'px');
      }, 0);
    }
  }

  private onTransitionEnd = () => {
    if (this.expanded) {
      this.refresh('visible', 'auto');
    }
  }

  private refresh(overflow: string, height: string) {
    this.overflow = overflow;
    this.height = height;
    this.changeDetector.detectChanges();
  }

  private addTransitionListeners() {
    this.removeTransitionListeners();
    this.transitionEndEvents.forEach((event) => {
      this.containerElement.nativeElement.addEventListener(event, this.onTransitionEnd, false);
    });
  }

  private removeTransitionListeners() {
    this.transitionEndEvents.forEach((event) => {
      this.containerElement.nativeElement.removeEventListener(event, this.onTransitionEnd, false);
    });
  }
}
