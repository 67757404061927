import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpaLoadingInlineComponent } from './cpa-loading-inline.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [CpaLoadingInlineComponent],
    exports: [CpaLoadingInlineComponent]
})
export class CpaLoadingInlineModule { }
