import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../directives/directives.module';
import { CpaFileImageComponent } from './cpa-file-img.component';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
  ],
  declarations: [
    CpaFileImageComponent,
  ],
  exports: [
    CpaFileImageComponent,
  ]
})
export class CpaFileImageModule {}
