import { Component, Input, Output, EventEmitter, OnInit, HostListener, HostBinding} from '@angular/core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'cpa-modal-header-left',
  templateUrl: './cpa-modal-header-left.component.html',
  styleUrls: ['./cpa-modal-header-left.component.scss']
  
})
export class CpaModalHeaderLeftComponent implements OnInit {
  @HostBinding('[class.cpa-ui-theme]')
  expanded = true
  @Input() title: string;
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();

  faTimes = faTimes;
  ngOnInit(): void {
  }

  clickOnCloseButton() {
    this.closeEvent.emit();
  }
}



