<div class="d-flex no-files" [ngClass]="{'required': required}" [@noFilesAnimation] [@.disabled]="config?.disableAnimations || !viewInitialized" *ngIf="!files?.length">
  <fa-icon class="leading-icon" [icon]="required ? faExclamationTriangle : faSadTear"></fa-icon>
  <div>
    <span>
      {{ required ? config?.captions?.required || 'Uploading a file is required.' : config?.captions?.empty || 'No files uploaded yet.' }}
    </span>
  </div>
</div>
<ng-container *ngFor="let file of files; let i = index">
  <div class="file-container overflow-hidden" [@fileAnimation] [@.disabled]="config?.disableAnimations || !viewInitialized">
    <cpa-file
      [class.clickable]="filesClickable"
      [file]="file"
      (remove)="removeFile(file, i)"
      [config]="config?.fileConfig"
      [templates]="fileTemplates"
      (fileClick)="fileClick.next(file)">
    </cpa-file>
  </div>
</ng-container>
