import { Component, OnInit, Input, AfterViewInit, TemplateRef, HostBinding } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { CpaFile } from '../cpa-file/cpa-file.models';
import { coerceBooleanProperty } from '../utils/input.utils';

@Component({
  selector: 'cpa-file-img',
  templateUrl: './cpa-file-img.component.html',
  styleUrls: ['./cpa-file-img.component.scss']
})
export class CpaFileImageComponent implements OnInit, AfterViewInit {

  src: string;
  @Input('src') set setSrc(src: string) {
    this.src = src;
    this.refresh();
  }
  @Input() fallbackTemplate: TemplateRef<any>;

  @HostBinding('class.cpa-file-img-contain') contain: boolean;
  @Input('contain') set setContain(value: any) {
    this.contain = coerceBooleanProperty(value);
  }

  @HostBinding('class.cpa-file-img-cover') cover: boolean;
  @Input('cover') set setCover(value: any) {
    this.cover = coerceBooleanProperty(value);
  }

  isSvg: boolean;
  error: string;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  drawSvgOnCanvas(canvas: HTMLCanvasElement): void {
    try {
      const context = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        const scale = Math.min(canvas.width / img.width, canvas.height / img.height);
        const x = (canvas.width / 2) - (img.width / 2) * scale;
        const y = (canvas.height / 2) - (img.height / 2) * scale;
        context.drawImage(
          img,
          x,
          y,
          img.width * scale,
          img.height * scale
        );
      };
      img.src = this.src;
    } catch (e) {
      // do nothing
    }
  }

  private refresh(): void {
    this.error = null;
    if (this.src) {
      this.isSvg = this.src.includes('image/svg+xml');
    }
  }
}
