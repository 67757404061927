<div class="item" [ngClass]="{ 'nested': this.parent !== null, 'selected': selected, 'collapsed': collapsed }"
    (click)="onClick()">
    <span class="leading-icon">
        <fa-icon *ngIf="icon" [icon]="icon"></fa-icon>
    </span>
    <span class="label">
        {{label}}
    </span>
    <span *ngIf="children.length === 0 && this.badgeValue > 0" class="badge">
        {{badgeNumber}}
    </span>
    <fa-icon *ngIf="!this.parent && children.length > 0" class="trailing-icon"
        [icon]="trailingIcon" (click)="toggleOpen($event)">
    </fa-icon>
</div>
<div *ngIf="!this.parent && children.length > 0" class="sub-items" [ngStyle]="{ 'height': subItemsViewHeight }">
    <div [ngClass]="{ 'sub-items-popout': this.collapsed }" [ngStyle]="{ 'left': popoutOffsetLeft + 'px', 'top': popoutOffsetTop + 'px' }">
        <div class="arrow">
            <ng-content select="cpa-side-menu-item"></ng-content>
            <div [ngStyle]="{ 'position': 'absolute', 'top': popoutArrowOffsetTopPx }">
                <div class="arrow-position"></div>
            </div>
        </div>
    </div>
</div>

