import { Component, OnInit, forwardRef, AfterViewInit, AfterContentInit,
AfterViewChecked, OnDestroy, Input, Output, EventEmitter,
ViewChild, ElementRef, ContentChildren, QueryList, ViewRef,
TemplateRef, Renderer2, ChangeDetectorRef, NgZone, HostListener, SimpleChanges, OnChanges } from '@angular/core';
import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { PrimeTemplate, LazyLoadEvent, FilterService } from 'primeng/api';
import { DomHandler } from 'primeng/dom';


import { faFilter, faChevronDown, faTimes, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { PaginationOptions } from './models/pagination-options';
import { ObjectUtils } from 'primeng/utils';

@Component({
  selector: 'cpa-select,cpa-dropdown-complete',
  templateUrl: './cpa-select.component.html',
  styleUrls: ['./cpa-select.component.scss'],
  animations: [
    trigger('overlayAnimation', [
        state('void', style({
            transform: 'translateY(5%)',
            opacity: 0
        })),
        state('visible', style({
            transform: 'translateY(0)',
            opacity: 1
        })),
        transition('void => visible', animate('{{showTransitionParams}}')),
        transition('visible => void', animate('{{hideTransitionParams}}'))
    ])
  ],
  providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CpaSelectComponent),
      multi: true
},
FilterService],
})
export class CpaSelectComponent implements OnInit,
AfterViewInit,
AfterContentInit,
AfterViewChecked,
OnDestroy,
ControlValueAccessor {
    public faFilter = faFilter;
    public faTimes = faTimes;
    private faChevronDown = faChevronDown;

    public hoveredOption: any;

    constructor(public el: ElementRef, public renderer: Renderer2,
                private cd: ChangeDetectorRef, public zone: NgZone, public fs: FilterService)
        {
            const tagName = el.nativeElement.tagName.toLowerCase();
            if (tagName === 'cpa-dropdown-complete') {
                console.warn(`'cpa-dropdown-complete' has been renamed to 'cpa-select'`);
            }
        }

    @Input() get disabled(): boolean {
        return this.dropdownDisabled;
    }

    set disabled(disabled: boolean) {
        if (disabled) {
            this.focused = false;
        }

        this.dropdownDisabled = disabled;
        if (!(this.cd as ViewRef).destroyed) {
            this.cd.detectChanges();
        }
    }

    @Input() get options(): any[] {
        return this.dropdownOptions;
    }

    set options(val: any[]) {
        const opts = this.optionLabel ? ObjectUtils.resolveFieldData(val, this.optionLabel) : val;
        this.dropdownOptions = opts;
        this.optionsToDisplay = this.dropdownOptions;
        this.optionsChanged = true;

        if (this.filterValue && this.filterValue.length) {
            this.activateFilter();
        }
    }

    @Input() get label(): string {
        return this.selectedOption ? this.selectedOption.label : this.labelValue;
    }

    set label(val: string) {
        if (val) {
            this.labelValue = val.trim();
        }
    }

    @Input() autoPosition = true;

    @Input() iconType: string;

    @Input() icon: string;

    @Input() labelHeaderText: string;

    @Input() helptext: string;

    @Input() scrollHeight = '400px';

    @Input() filter: boolean;

    @Input() searchDataFromSource = false;

    @Input() multiple = false;

    @Input() name: string;

    @Input() style: any;

    @Input() panelStyle: any;

    @Input() styleClass: string;

    @Input() panelStyleClass: string;

    @Input() readonly: boolean;

    @Input() required: boolean;

    @Input() editable: boolean;

    @Input() appendTo: any;

    @Input() tabindex: number;

    @Input() placeholder: string;

    @Input() filterPlaceholder: string;

    @Input() filterHeaderText: string;

    @Input() inputId: string;

    @Input() selectId: string;

    @Input() dataKey: string;

    @Input() filterBy = 'label';

    @Input() autofocus: boolean;

    @Input() resetFilterOnHide = true;

    @Input() dropdownIcon: string | IconDefinition = this.faChevronDown;

    @Input() dropdownIconStyle = 'far';

    @Input() unique = true;

    @Input() optionLabel: string;

    @Input() autoDisplayFirst = true;

    @Input() group: boolean;

    @Input() showClear: boolean;

    @Input() emptyFilterMessage = 'No results found';

    @Input() virtualScroll: boolean;

    @Input() itemSize: number;

    @Input() autoZIndex = true;

    @Input() baseZIndex = 0;

    @Input() showTransitionOptions = '225ms ease-out';

    @Input() hideTransitionOptions = '195ms ease-in';

    @Input() ariaFilterLabel: string;

    @Input() ariaLabelledBy: string;

    @Input() filterMatchMode = 'contains';

    @Input() maxlength: number;

    @Input() tooltip = '';

    @Input() tooltipPosition = 'right';

    @Input() tooltipPositionStyle = 'absolute';

    @Input() tooltipStyleClass: string;

    @Input() chipTooltipPosition = 'bottom-center';

    @Input() paginationOptions: PaginationOptions;

    @Output() dropdownChange: EventEmitter<any> = new EventEmitter();

    @Output() dropdownFocus: EventEmitter<any> = new EventEmitter();

    @Output() dropdownBlur: EventEmitter<any> = new EventEmitter();

    @Output() dropdownClick: EventEmitter<any> = new EventEmitter();

    @Output() dropdownShow: EventEmitter<any> = new EventEmitter();

    @Output() dropdownHide: EventEmitter<any> = new EventEmitter();

    @Output() filterSearch: EventEmitter<string> = new EventEmitter<string>();

    @Output() pageChange: EventEmitter<LazyLoadEvent> = new EventEmitter();

    @Output() itemRemoved: EventEmitter<any> = new EventEmitter();

    @ViewChild('container', { static: true }) containerViewChild: ElementRef;

    @ViewChild('filter', { read: ElementRef }) filterViewChild?: ElementRef;

    @ViewChild('in', { static: true }) focusViewChild: ElementRef;

    @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

    @ViewChild('editableInput') editableInputViewChild: ElementRef;

    @ContentChildren(PrimeTemplate) templates: QueryList<any>;

    private dropdownDisabled: boolean;

    selectedOptionsCount: number;

    chipSelectedOptionsList: string;

    labelValue: string;

    overlay: HTMLDivElement;

    itemsWrapper: HTMLDivElement;

    paginatorWrapper: HTMLDivElement;

    itemTemplate: TemplateRef<any>;

    groupTemplate: TemplateRef<any>;

    selectedItemTemplate: TemplateRef<any>;

    selectedOption: any;

    selectedOptions: any[];

    dropdownOptions: any[];

    value: any;

    optionsToDisplay: any[];

    hover = false;

    focused: boolean;

    filled: boolean;

    overlayVisible: boolean;

    documentClickListener: any;

    optionsChanged: boolean;

    panel: HTMLDivElement;

    dimensionsUpdated: boolean;

    selfClick: boolean;

    itemClick: boolean;

    paginatorClick: boolean;

    hoveredItem: any;

    selectedOptionUpdated: boolean;

    filterValue: string;

    searchValue: string;

    searchIndex: number;

    searchTimeout: any;

    previousSearchChar: string;

    currentSearchChar: string;

    documentResizeListener: any;

    virtualAutoScrolled: boolean;

    virtualScrollSelectedIndex: number;

    viewPortOffsetTop = 0;

    paginationOffset = 0;

    get dropDownTriggerIcon() {
      if (typeof this.dropdownIcon === 'string') {
        return [this.dropdownIconStyle, this.dropdownIcon];
      } else {
        return this.dropdownIcon;
      }
    }

    get showPagination(): boolean {
      const minRowsPerPage = (this.paginationOptions?.rowsPerPageOptions && this.paginationOptions.rowsPerPageOptions[0]) || 10;
      return this.paginationOptions && this.paginationOptions.totalRecords > minRowsPerPage;
    }

    @HostListener('document:click', ['$event'])
    clickOutside(event) {
        if (!this.el.nativeElement.contains(event.target)) {
            this.focused = false;
        }
    }

    onModelChange: (val: any) => void = () => {};

    onModelTouched: () => void = () => {};

    ngAfterContentInit() {
        this.templates.forEach((item) => {
            switch (item.getType()) {
                case 'item':
                    this.itemTemplate = item.template;
                    break;

                case 'selectedItem':
                    this.selectedItemTemplate = item.template;
                    break;

                case 'group':
                    this.groupTemplate = item.template;
                    break;

                default:
                    this.itemTemplate = item.template;
                    break;
            }
        });
    }

    ngOnInit() {
        this.optionsToDisplay = this.options;
    }

    ngAfterViewInit() {
        if (this.editable) {
            this.updateEditableLabel();
        }
    }

    updateEditableLabel(): void {
        if (this.editableInputViewChild && this.editableInputViewChild.nativeElement) {
            this.editableInputViewChild.nativeElement.value = (this.selectedOption ? this.selectedOption.label : this.value || '');
        }
    }

    onItemClick(event) {
        if (!event.option.disabled) {
            this.selectItem(event, event.option);
            this.focusViewChild.nativeElement.focus();
        }
        this.itemClick = true;

        if (!this.multiple) {
            setTimeout(() => {
                this.hide(event);
            }, 10);
        }
    }

    onDropdownItemHovered(event: {hovered: boolean, option: any}) {
        if (event.hovered) {
            this.hoveredOption = event.option;
        }
    }

    onDropdownItemRemoved(event) {
        this.overlayVisible = false;

        this.itemRemoved.emit({
            optionToRemove: event.option
        });

        if (this.multiple) {
            this.resetSelectedOptions();
        }
    }

    isItemSelected(option) {
      if (this.multiple) {
        return !!(option?.value) &&
            ObjectUtils.equals(
                option.value,
                this.selectedOptions?.find(selOpt => selOpt.value === option.value)?.value,
                this.dataKey
            );
      }

      return !!(option?.value) &&
        ObjectUtils.equals(option.value, this.selectedOption?.value, this.dataKey);
    }

    selectItem(event, option) {
        if (this.multiple) {
            this.value = this.value || [];
            this.selectedOptions = this.selectedOptions || [];
            if (!this.multipleIsSelected(option.value) || !this.unique) {
                this.value = [...this.value, option.value];
                this.selectedOptions = [...this.selectedOptions, option];
            } else {
                const selectItemIndex = this.findOptionIndex(option.value, this.selectedOptions);
                this.value = this.value.filter((val, i) => i !== selectItemIndex);
                this.selectedOptions = this.selectedOptions.filter((val, i) => i !== selectItemIndex);
                this.resetSelectedOptions();
            }

            this.countSelectedOptions();
        } else {
            this.hoveredOption = null;
            this.selectedOption = option;
            this.value = option ? option.value : null;
        }
        this.onModelChange(this.value);
        this.updateEditableLabel();
        this.updateFilledState();
        this.dropdownChange.emit({
            originalEvent: event.originalEvent,
            value: this.value,
            optionSelected: option
        });

        if (this.virtualScroll) {
            setTimeout(() => {
                this.viewPortOffsetTop = this.viewPort.measureScrollOffset();
            }, 1);
        }
    }

    multipleIsSelected(val: any): boolean {
        let selected = false;
        if (this.value && this.value.length) {
            for (const selectedValue of this.value) {
                if (ObjectUtils.equals(selectedValue, val, this.dataKey)) {
                    selected = true;
                    break;
                }
            }
        }
        return selected;
    }

    removeListItemMultiSelection(event: any, listOption: any) {
        const itemIndex = this.selectedOptions.indexOf(listOption);
        this.value = this.value.filter((val, i) => i !== itemIndex);
        this.selectedOptions = this.selectedOptions.filter((val, i) => i !== itemIndex);

        this.resetSelectedOptions();

        this.onModelChange(this.value);
        this.updateFilledState();

        this.countSelectedOptions();

        event.stopPropagation();
    }

    ngAfterViewChecked() {
        if (this.optionsChanged && this.overlayVisible) {
            this.optionsChanged = false;

            if (this.virtualScroll) {
                this.updateVirtualScrollSelectedIndex(true);
            }

            this.zone.runOutsideAngular(() => {
                setTimeout(() => {
                    this.alignOverlay();
                }, 1);
            });
        }

        if (this.selectedOptionUpdated && this.itemsWrapper) {
            if (this.virtualScroll && this.viewPort) {
                const range = this.viewPort.getRenderedRange();
                this.updateVirtualScrollSelectedIndex(false);

                if (range.start > this.virtualScrollSelectedIndex || range.end < this.virtualScrollSelectedIndex) {
                    this.viewPort.scrollToIndex(this.virtualScrollSelectedIndex);
                }
            }

            const selectedItem = DomHandler.findSingle(this.overlay, 'li.ui-state-highlight');
            if (selectedItem) {
                DomHandler.scrollInView(this.itemsWrapper, DomHandler.findSingle(this.overlay, 'li.ui-state-highlight'));
            }
            this.selectedOptionUpdated = false;
        }
    }

    writeValue(value: any): void {
        if (this.filter) {
            this.resetFilter();
        }

        this.value = value;
        this.selectedOptions = null;
        this.updateSelectedOption(value);
        this.onModelChange(this.value);
        this.updateEditableLabel();
        this.updateFilledState();
        this.cd.markForCheck();
    }

    resetFilter(): void {
        this.filterValue = null;

        if (this.filterViewChild && this.filterViewChild.nativeElement) {
            this.filterViewChild.nativeElement.value = '';
        }

        this.optionsToDisplay = this.options;
    }

    updateSelectedOption(val: any): void {
        if (this.multiple && val) {
            this.selectedOptions = this.selectedOptions || [];
            if (Array.isArray(val)) {
                val.forEach(value => {
                    this.selectedOptions = [...this.selectedOptions, this.findOption(value, this.optionsToDisplay)];
                });
            } else {
                this.selectedOptions = [...this.selectedOptions, this.findOption(val, this.optionsToDisplay)];
            }
        } else {
            this.selectedOption = this.findOption(val, this.optionsToDisplay);
        }
        this.countSelectedOptions();
        this.selectedOptionUpdated = true;
    }

    registerOnChange(fn: () => void): void {
        this.onModelChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onModelTouched = fn;
    }

    setDisabledState(val: boolean): void {
        this.disabled = val;
    }

    onMouseclick(event: MouseEvent) {
      if (this.disabled || this.readonly) {
        return;
      }

      this.dropdownClick.emit(event);
      this.focusViewChild.nativeElement.focus();

      this.selfClick = true;
      this.paginatorClick = event.target === this.paginatorWrapper ||
        this.paginatorWrapper?.contains(event.target as HTMLElement);

      if (
        !this.itemClick &&
        !this.paginatorClick
      ) {
        this.dropdownClick.emit(event);
        this.focusViewChild.nativeElement.focus();

        if (this.overlayVisible) {
          this.hide(event);
        } else {
          this.show();
        }
      }
    }

    onEditableInputClick(event) {
        this.itemClick = true;
        this.bindDocumentClickListener();
    }

    onEditableInputFocus(event) {
        this.focused = true;
        this.hide(event);
        this.dropdownFocus.emit(event);
    }

    onEditableInputChange(event) {
        this.value = event.target.value;
        this.updateSelectedOption(this.value);
        this.onModelChange(this.value);
        this.dropdownChange.emit({
            originalEvent: event,
            value: this.value
        });
    }

    show() {
        this.overlayVisible = true;
    }

    onOverlayAnimationStart(event: AnimationEvent) {
        switch (event.toState) {
            case 'visible':{
                this.overlay = event.element;
                const itemsWrapperSelector = this.virtualScroll ? '.cdk-virtual-scroll-viewport' : '.ui-dropdown-items-wrapper';
                this.itemsWrapper = DomHandler.findSingle(this.overlay, itemsWrapperSelector);
                this.paginatorWrapper = DomHandler.findSingle(this.overlay, '.pagination-container');

                this.appendOverlay();
                if (this.autoZIndex) {
                    this.overlay.style.zIndex = String(this.baseZIndex + (++DomHandler.zindex));
                }
                this.alignOverlay();
                this.bindDocumentClickListener();
                this.bindDocumentResizeListener();

                if (this.options && this.options.length) {
                    if (!this.virtualScroll) {
                        const selectedListItem = DomHandler.findSingle(this.itemsWrapper, '.ui-dropdown-item.ui-state-highlight');
                        if (selectedListItem) {
                            DomHandler.scrollInView(this.itemsWrapper, selectedListItem);
                        }
                    }
                }

                if (this.filterViewChild && this.filterViewChild.nativeElement) {
                    DomHandler.findSingle(this.filterViewChild.nativeElement, 'input').focus();
                }

                this.hoveredOption = null;
                this.dropdownShow.emit(event);
                break;
            }
            case 'void':{
                this.onOverlayHide();
                break;
            }
        }
    }

    scrollToSelectedVirtualScrollElement() {
        if (!this.virtualAutoScrolled) {
            if (this.viewPortOffsetTop) {
                this.viewPort.scrollToOffset(this.viewPortOffsetTop);
            } else if (this.virtualScrollSelectedIndex > -1) {
                this.viewPort.scrollToIndex(this.virtualScrollSelectedIndex);
            }
        }

        this.virtualAutoScrolled = true;
    }

    updateVirtualScrollSelectedIndex(resetOffset) {
        if (this.selectedOption && this.optionsToDisplay && this.optionsToDisplay.length) {
            if (resetOffset) {
                this.viewPortOffsetTop = 0;
            }

            this.virtualScrollSelectedIndex = this.findOptionIndex(this.selectedOption.value, this.optionsToDisplay);
        }
    }

    appendOverlay() {
        if (this.appendTo) {
            if (this.appendTo === 'body') {
                document.body.appendChild(this.overlay);
            } else {
                DomHandler.appendChild(this.overlay, this.appendTo);
            }

            this.overlay.style.minWidth = DomHandler.getWidth(this.containerViewChild.nativeElement) + 'px';
        }
    }

    restoreOverlayAppend() {
        if (this.overlay && this.appendTo) {
            this.el.nativeElement.appendChild(this.overlay);
        }
    }

    hide(event) {
        this.overlayVisible = false;

        if (this.filter && this.resetFilterOnHide) {
            this.resetFilter();
        }

        if (this.virtualScroll) {
            this.virtualAutoScrolled = false;
        }

        this.cd.markForCheck();
        this.dropdownHide.emit(event);
    }

    alignOverlay() {
        if (this.overlay) {
            if (this.appendTo) {
                DomHandler.absolutePosition(this.overlay, this.containerViewChild.nativeElement);
            } else {
                if (this.autoPosition) {
                    DomHandler.relativePosition(this.overlay, this.containerViewChild.nativeElement);
                }
            }
        }
    }

    onInputFocus(event) {
        this.focused = true;
        this.dropdownFocus.emit(event);
    }

    onInputBlur(event) {
        this.onModelTouched();
        this.dropdownBlur.emit(event);
    }

    findPrevEnabledOption(index) {
        let prevEnabledOption;

        if (this.optionsToDisplay && this.optionsToDisplay.length) {
            for (let i = (index - 1); 0 <= i; i--) {
                const option = this.optionsToDisplay[i];
                if (option.disabled) {
                    continue;
                } else {
                    prevEnabledOption = option;
                    break;
                }
            }

            if (!prevEnabledOption) {
                for (let i = this.optionsToDisplay.length - 1; i >= index ; i--) {
                    const option = this.optionsToDisplay[i];
                    if (option.disabled) {
                        continue;
                    } else {
                        prevEnabledOption = option;
                        break;
                    }
                }
            }
        }

        return prevEnabledOption;
    }

    findNextEnabledOption(index) {
        let nextEnabledOption;

        if (this.optionsToDisplay && this.optionsToDisplay.length) {
            for (let i = (index + 1); index < (this.optionsToDisplay.length - 1); i++) {
                const option = this.optionsToDisplay[i];
                if (option.disabled) {
                    continue;
                } else {
                    nextEnabledOption = option;
                    break;
                }
            }

            if (!nextEnabledOption) {
                for (let i = 0; i < index; i++) {
                    const option = this.optionsToDisplay[i];
                    if (option.disabled) {
                        continue;
                    } else {
                        nextEnabledOption = option;
                        break;
                    }
                }
            }
        }

        return nextEnabledOption;
    }

    onKeydown(event: KeyboardEvent, search: boolean) {
        if (this.readonly || !this.optionsToDisplay || this.optionsToDisplay.length === null) {
            return;
        }

        switch (event.key) {
            // down
            case 'ArrowDown':
                if (!this.overlayVisible && event.altKey) {
                    this.show();
                } else {
                    if (this.group) {
                        const selectedItemIndex = this.hoveredOption ?
                        this.findOptionGroupIndex(this.hoveredOption.value, this.optionsToDisplay) : -1;

                        if (selectedItemIndex !== -1) {
                            const nextItemIndex = selectedItemIndex.itemIndex + 1;
                            if (nextItemIndex < (this.optionsToDisplay[selectedItemIndex.groupIndex].items.length)) {
                                this.hoveredOption = this.optionsToDisplay[selectedItemIndex.groupIndex].items[nextItemIndex];
                            } else if (this.optionsToDisplay[selectedItemIndex.groupIndex + 1]) {
                                this.hoveredOption = this.optionsToDisplay[selectedItemIndex.groupIndex + 1].items[0];
                            }
                        } else {
                            this.hoveredOption = this.optionsToDisplay[0].items[0];
                        }
                    } else {
                        const selectedItemIndex = this.hoveredOption ?
                        this.findOptionIndex(this.hoveredOption.value, this.optionsToDisplay) : -1;
                        const nextEnabledOption = this.findNextEnabledOption(selectedItemIndex);
                        if (nextEnabledOption) {
                            this.hoveredOption = nextEnabledOption;
                        }
                    }
                }

                event.preventDefault();

                break;

            // up
            case 'ArrowUp':
                if (this.group) {
                    const selectedItemIndex = this.hoveredOption ?
                    this.findOptionGroupIndex(this.hoveredOption.value, this.optionsToDisplay) : -1;
                    if (selectedItemIndex !== -1) {
                        const prevItemIndex = selectedItemIndex.itemIndex - 1;
                        if (prevItemIndex >= 0) {
                            this.hoveredOption = this.optionsToDisplay[selectedItemIndex.groupIndex].items[prevItemIndex];
                        } else if (prevItemIndex < 0) {
                            const prevGroup = this.optionsToDisplay[selectedItemIndex.groupIndex - 1];
                            if (prevGroup) {
                                this.hoveredOption = prevGroup.items[prevGroup.items.length - 1];
                            }
                        }
                    }
                } else {
                    const selectedItemIndex = this.hoveredOption ?
                    this.findOptionIndex(this.hoveredOption.value, this.optionsToDisplay) : -1;
                    const prevEnabledOption = this.findPrevEnabledOption(selectedItemIndex);
                    if (prevEnabledOption) {
                        this.hoveredOption = prevEnabledOption;
                    }
                }

                event.preventDefault();
                break;

            // space
            case ' ':
                if (!this.overlayVisible) {
                    this.show();
                    event.preventDefault();
                }
                break;

            // enter
            case 'Enter':
            case 'Tab':
                if (!this.filter || (this.optionsToDisplay && this.optionsToDisplay.length > 0)) {
                    this.selectItem(event, this.hoveredOption);
                    this.selectedOptionUpdated = true;
                    this.hide(event);
                }

                event.preventDefault();
                break;

            // escape and tab
            case 'Escape':
                this.hide(event);
                break;

            // search item based on keyboard input
            default:
                if (search) {
                    this.search(event);
                }
                break;
        }
    }

    search(event) {
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }

        const char = event.key;
        this.previousSearchChar = this.currentSearchChar;
        this.currentSearchChar = char;

        if (this.previousSearchChar === this.currentSearchChar) {
            this.searchValue = this.currentSearchChar;
        } else {
            this.searchValue = this.searchValue ? this.searchValue + char : char;
        }

        let newOption;
        if (this.group) {
            const searchIndex = this.selectedOption ?
            this.findOptionGroupIndex(this.selectedOption.value, this.optionsToDisplay) : {groupIndex: 0, itemIndex: 0};
            newOption = this.searchOptionWithinGroup(searchIndex);
        } else {
            let searchIndex = this.selectedOption ? this.findOptionIndex(this.selectedOption.value, this.optionsToDisplay) : -1;
            newOption = this.searchOption(++searchIndex);
        }

        if (newOption) {
            this.selectItem(event, newOption);
            this.selectedOptionUpdated = true;
        }

        this.searchTimeout = setTimeout(() => {
            this.searchValue = null;
        }, 250);
    }

    searchOption(index) {
        let option;

        if (this.searchValue) {
            option = this.searchOptionInRange(index, this.optionsToDisplay.length);

            if (!option) {
                option = this.searchOptionInRange(0, index);
            }
        }

        return option;
    }

    searchOptionInRange(start, end) {
        for (let i = start; i < end; i++) {
            const opt = this.optionsToDisplay[i];
            if (opt.label.toLowerCase().startsWith(this.searchValue.toLowerCase())) {
                return opt;
            }
        }

        return null;
    }

    searchOptionWithinGroup(index) {
        const option = null;

        if (this.searchValue) {
            for (let i = index.groupIndex; i < this.optionsToDisplay.length; i++) {
                for (let j = (index.groupIndex === i) ? (index.itemIndex + 1) : 0; j < this.optionsToDisplay[i].items.length; j++) {
                    const opt = this.optionsToDisplay[i].items[j];
                    if (opt.label.toLowerCase().startsWith(this.searchValue.toLowerCase())) {
                        return opt;
                    }
                }
            }

            if (!option) {
                for (let i = 0; i <= index.groupIndex; i++) {
                    for (let j = 0; j < ((index.groupIndex === i) ? index.itemIndex : this.optionsToDisplay[i].items.length); j++) {
                        const opt = this.optionsToDisplay[i].items[j];
                        if (opt.label.toLowerCase().startsWith(this.searchValue.toLowerCase())) {
                            return opt;
                        }
                    }
                }
            }
        }

        return null;
    }

    findOptionIndex(val: any, opts: any[]): number {
        let index = -1;
        if (opts) {
            for (let i = 0; i < opts.length; i++) {
                if ((val == null && opts[i].value == null) || ObjectUtils.equals(val, opts[i].value, this.dataKey)) {
                    index = i;
                    break;
                }
            }
        }

        return index;
    }

    findOptionGroupIndex(val: any, opts: any[]): any {
        let groupIndex;
        let itemIndex;

        if (opts) {
            for (let i = 0; i < opts.length; i++) {
                groupIndex = i;
                itemIndex = this.findOptionIndex(val, opts[i].items);

                if (itemIndex !== -1) {
                    break;
                }
            }
        }

        if (itemIndex !== -1) {
            return {groupIndex, itemIndex};
        } else {
            return -1;
        }
    }

    findOption(val: any, opts: any[], inGroup?: boolean): any {
        if (this.group && !inGroup) {
            let opt: any;
            if (opts && opts.length) {
                for (const optgroup of opts) {
                    opt = this.findOption(val, optgroup.items, true);
                    if (opt) {
                        break;
                    }
                }
            }
            return opt;
        } else {
            const index: number = this.findOptionIndex(val, opts);
            return (index !== -1) ? opts[index] : null;
        }
    }

    onFilter(event): void {
        const inputValue = event.target.value;

        if (!this.searchDataFromSource) {
            if (inputValue && inputValue.length) {
                this.filterValue = inputValue;
                this.activateFilter();
            } else {
                this.filterValue = null;
                this.optionsToDisplay = this.options;
            }
        }

        this.filterSearch.emit(inputValue);

        this.optionsChanged = true;
    }

    onPageChange(event: any) {
      this.paginationOffset = event.first;
      this.paginationOptions.rowsPerPage = event.rows;

      const lazyLoadEvent = {
        first: event.first,
        rows: event.rows
      };

      this.pageChange.emit(lazyLoadEvent);
    }

    activateFilter() {
        const searchFields: string[] = this.filterBy.split(',');

        if (this.options && this.options.length) {
            if (this.group) {
                const filteredGroups = [];
                for (const optgroup of this.options) {
                    const filteredSubOptions = this.fs.filter(optgroup.items,
                         searchFields, this.filterValue, this.filterMatchMode);
                    if (filteredSubOptions && filteredSubOptions.length) {
                        filteredGroups.push({
                            label: optgroup.label,
                            value: optgroup.value,
                            items: filteredSubOptions
                    });
                    }
                }

                this.optionsToDisplay = filteredGroups;
            } else {
                this.optionsToDisplay = this.fs.filter (this.options, searchFields, this.filterValue, this.filterMatchMode);
            }

            this.optionsChanged = true;
        }
    }

    applyFocus(): void {
        if (this.editable) {
            DomHandler.findSingle(this.el.nativeElement, '.ui-dropdown-label.ui-inputtext').focus();
        } else {
            DomHandler.findSingle(this.el.nativeElement, 'input[readonly]').focus();
        }
    }

    focus(): void {
        this.applyFocus();
    }

    bindDocumentClickListener() {
      if (!this.documentClickListener) {
        this.documentClickListener = this.renderer.listen('document', 'click', (event: UIEvent) => {
            if (
            !this.selfClick &&
            !this.itemClick &&
            event.target !== this.paginatorWrapper &&
            !this.paginatorWrapper?.contains(event.target as HTMLElement)
          ) {
            this.hide(event);
            this.unbindDocumentClickListener();
          }

            this.clearClickState();
            this.cd.markForCheck();
        });
      }
    }

    clearClickState() {
        this.selfClick = false;
        this.itemClick = false;
        this.paginatorClick = false;
    }

    unbindDocumentClickListener() {
        if (this.documentClickListener) {
            this.documentClickListener();
            this.documentClickListener = null;
        }
    }

    bindDocumentResizeListener() {
        this.documentResizeListener = this.onWindowResize.bind(this);
        window.addEventListener('resize', this.documentResizeListener);
    }

    unbindDocumentResizeListener() {
        if (this.documentResizeListener) {
            window.removeEventListener('resize', this.documentResizeListener);
            this.documentResizeListener = null;
        }
    }

    onWindowResize() {
        if (!DomHandler.isAndroid()) {
            this.hide({});
        }
    }

    updateFilledState() {
        if (this.multiple) {
            this.filled = (this.value && this.value.length);
        } else {
            this.filled = (this.selectedOption !== null);
        }
    }

    clear(event: Event) {
        event.stopPropagation();

        this.value = null;
        this.onModelChange(this.value);
        this.dropdownChange.emit({
            originalEvent: event,
            value: this.value
        });
        this.updateSelectedOption(this.value);
        this.updateEditableLabel();
        this.updateFilledState();
    }

    onOverlayHide() {
        this.unbindDocumentClickListener();
        this.unbindDocumentResizeListener();
        this.overlay = null;
        this.itemsWrapper = null;
        this.paginatorWrapper = null;
    }

    createChipSelectedOptionsList() {
        this.chipSelectedOptionsList = '';

        const options = this.selectedOptions.map(obj => ({...obj}));
        options.splice(0, 2);
        if (options) {
            const optionsLabelList = options.reduce((acc, val) => acc.concat(val.label), []);
            this.chipSelectedOptionsList = optionsLabelList.join(', ');
        }
    }

    private resetSelectedOptions() {
        if (this.selectedOptions?.length === 0) {
            this.selectedOptions = null;
        }
    }

    private countSelectedOptions() {
        this.selectedOptionsCount = this.selectedOptions ? this.selectedOptions.length : 0;
    }

    ngOnDestroy() {
        this.restoreOverlayAppend();
        this.onOverlayHide();
    }
  }
