import { Component, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'cpa-side-menu-collapse',
  styleUrls: ['./side-menu-collapse.component.scss'],
  templateUrl: './side-menu-collapse.component.html',
})
export class CpaSideMenuCollapseComponent {
  public isCollapsed: boolean;
  @Input() set collapsed(value: boolean) {
    this.isCollapsed = value;
    this.icon = this.isCollapsed ? faAngleDoubleRight : faAngleDoubleLeft;
  }

  public icon: IconDefinition;
}
