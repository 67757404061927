import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cpa-helptext',
  templateUrl: './cpa-helptext.component.html',
  styleUrls: ['./cpa-helptext.component.scss'],
})
export class CpaHelptextComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
