import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

import { INotification, IApplication } from '../../api/platform-api.service';
import { HtmlStripperUtil } from '../../utils/html-strip.util';
import { faComment } from '@fortawesome/pro-regular-svg-icons';
import { faCircle, faChevronRight } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'cpa-notifications',
  styleUrls: ['./notifications.component.scss'],
  templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnChanges {
  public faComment = faComment;
  public faCircle = faCircle;
  public faChevronRight = faChevronRight;

  @Input() public readonly notifications: INotification[];
  @Input() public readonly apps: IApplication[];
  @Input() public readonly coreHome: string;
  @Output() public readonly settingloader: EventEmitter<void> = new EventEmitter<void>();

  constructor(public htmlStripper: HtmlStripperUtil) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.notifications) {
      return;
    }

    (changes.notifications.currentValue as INotification[])
      .sort((a, b) => b.occurredAt.toString().localeCompare(a.occurredAt.toString()));
  }

  /**
   * Gets the user visible application name from a notification
   * @param notification the notification
   */
  public getAppName(notification: INotification): string {
    if (!this.apps.length) {
      return 'Loading...';
    }

    const matchingApp = this.apps.find((app) => app.manifest.id === notification.applicationClientId);

    return matchingApp ? matchingApp.manifest.name : notification.applicationClientId;
  }

  public loadSettings(): void {
    this.settingloader.emit(null);
  }

}
