import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { CpaFile, CpaFileTemplates } from '../cpa-file/cpa-file.models';
import { CpaFileRemoveEvent, CpaFileListConfig } from './cpa-file-list.models';
import { trigger, transition, style, animate } from '@angular/animations';
import { faSadTear, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { CpaFileComponent } from '../cpa-file/cpa-file.component';

@Component({
  selector: 'cpa-file-list',
  templateUrl: './cpa-file-list.component.html',
  styleUrls: ['./cpa-file-list.component.scss'],
  animations: [
    trigger(
      'fileAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: '0rem', 'margin-bottom': '0rem', 'padding-top': '0rem', 'padding-bottom': '0rem', opacity: 0 }),
            animate('.35s ease-in-out', style({ height: '*', 'margin-bottom': '*', 'padding-top': '*', 'padding-bottom': '*', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: '*', 'margin-bottom': '*', 'padding-top': '*', 'padding-bottom': '*', opacity: 1 }),
            animate('.35s ease-in-out', style({ height: '0rem', 'margin-bottom': '0rem', 'padding-top': '0rem',
                    'padding-bottom': '0rem', opacity: 0 }))
          ]
        )
      ]
    ),
    trigger(
      'noFilesAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0, transform: 'scale(0.95)' }),
            animate('.35s .35s ease-out', style({ opacity: 1, transform: 'scale(1)' }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1, transform: 'scale(1)' }),
            animate('.35s ease-out', style({ opacity: 0, transform: 'scale(0.95)' }))
          ]
        )
      ]
    )],
})
export class CpaFileListComponent implements OnInit, AfterViewInit {
  faExclamationTriangle = faExclamationTriangle;
  faSadTear = faSadTear;

  @Input() required = false;
  @Input() config: CpaFileListConfig;
  @Input() fileTemplates: CpaFileTemplates;
  @Input() files: CpaFile[];
  @Input() filesClickable: boolean;

  @Output() remove = new EventEmitter<CpaFileRemoveEvent>();
  @Output() fileClick = new EventEmitter<CpaFile>();

  originalFiles: CpaFileComponent[];
  viewInitialized: boolean;

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.originalFiles = [].concat(this.files);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewInitialized = true;
      this.changeDetector.detectChanges();
    });
  }

  removeFile(file: CpaFile, index: number) {
    if (this.remove.observers.length > 0) {
      this.remove.emit({
        file,
        index
      });
    } else {
      this.files.splice(index, 1);
    }
  }
}
