import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cpa-errortext',
  templateUrl: './cpa-errortext.component.html',
  styleUrls: ['./cpa-errortext.component.scss'],
})
export class CpaErrortextComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
