import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpaLoadingFullscreenComponent } from './cpa-loading-fullscreen.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [CpaLoadingFullscreenComponent],
    exports: [CpaLoadingFullscreenComponent]
})
export class CpaLoadingFullscreenModule { }
