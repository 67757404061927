<button class="btn"
    (click)="click($event)"
    [ngClass]="{
        'btn-primary':          type === 'primary',
        'btn-secondary':        type === 'secondary',
        'btn-danger':           type === 'danger',
        'btn-info':             type === 'info',
        'btn-warning':          type === 'warning',
        'btn-success':          type === 'success',
        'btn-outlined': outlined,
        'btn-outline-primary':   outlined && type === 'primary',
        'btn-outline-secondary': outlined && type === 'secondary',
        'btn-outline-danger':    outlined && type === 'danger',
        'btn-outline-info':      outlined && type === 'info',
        'btn-outline-warning':   outlined && type === 'warning',
        'btn-outline-success':   outlined && type === 'success',
        'disabled': disabled}"
    [attr.disabled]="disabled ? 'disabled' : null">
    <span *ngIf="!small || !icon">{{label}}</span>
    <fa-icon *ngIf="isIconTypeString" [icon]="[iconType, icon]" aria-hidden="true"></fa-icon>
    <fa-icon *ngIf="isIconTypeIconDefinition" [icon]="icon" aria-hidden="true"></fa-icon>
</button>
