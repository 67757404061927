
<div class="modal-backdrop"></div>

<div 
   class="modal fade show d-block" 
   role="dialog" 
   aria-labelledby="modal-cpamodal-label" 
   aria-hidden="true">

   <div class="modal-dialog {{ modalSize}}">
      <div class="modal-content">
   
         <div class="modal-header">
            <span #headerPlaceholder><ng-content  select="div[role=header]"></ng-content></span>
            <cpa-modal-header-left 
               *ngIf="!headerPlaceholder.innerHTML.trim() && !!header"
               (closeEvent)="closeIconClick()"
               title="{{header}}"></cpa-modal-header-left>
         </div>
       
         <div 
            class="modal-body {{(canScrollDown ? 'can-scroll-down' : '')}} {{(canScrollUp ? 'can-scroll-up' : '')}}">
            <div class="content-wrapper" cpaScroll 
               (canScrollUp)="canScroll('up', $event)" 
               (canScrollDown)="canScroll('down', $event)"> 
               <ng-content selector="[main]"></ng-content>
            </div> 
         </div>

         <div class="modal-footer">
            <span #headerPlaceholder><ng-content select="div[role=footer]"></ng-content></span>
         </div>
      </div> 
   </div>
</div>


